import NumberAbbreviate from "number-abbreviate";

const abbreviate = (number, comma) => {
  const flag = typeof number === "number" && number % 1 != 0;
  return (
    NumberAbbreviate(flag ? number.toFixed(1) : number, comma || 1) + ""
  ).toUpperCase();
};

export default abbreviate;
