import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { StyledContainer, StyledGallery, StyledMarketTab } from "../../styled";
import { Filter, Gallery, MarketInfo } from "../../components";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet,
} from "react-device-detect";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { gsap } from "gsap";
import { useDispatch } from "react-redux";
import { fetchDefaultOnchainData } from "../../redux/data/dataActions";

function Rarity(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const [openFilter, setOpenFilter] = useState(false);
  const [tab, setTab] = useState("items");
  const [changedActivity, setChangedActivity] = useState(false);
  const [currentPathname, setCurrentPathname] = useState(location.pathname);
  const [renderComponent, setRenderComponent] = useState(true);
  const [showFilter, setShowFilter] = useState(true);

  const changeTab = (value) => {
    if (tab !== value) {
      setTab(value);
    }
  };

  let filterElem = document.querySelector("#filter");

  let hideFilterTL = gsap.timeline({ paused: true });
  hideFilterTL.to("#filter", {
    duration: 0.75,
    x: () => filterElem.offsetWidth * 1.5, // animate by the px width of the nav
    xPercent: -50, // offset by the width of the box
    ease: "power1.out",
    opacity: 0,
    display: "none",
    flex: 0,
  });
  hideFilterTL.to(
    "#gallery",
    {
      duration: 0.5,
      ease: "power1.out",
      position: "absolute",
      flex: 1,
      width: "100%",
    },
    "<"
  );
  hideFilterTL.to(
    "#showFilter",
    {
      duration: 1,
      ease: "power1.out",
      opacity: 1,
      pointerEvents: "auto",
    },
    "<25%"
  );
  hideFilterTL.to(
    ".nftContainer",
    {
      duration: 0.5,
      ease: "power1.out",
      gridTemplateColumns: "18.5% 18.5% 18.5% 18.5% 18.5%",
    },
    "<"
  );

  const playAnimation = () => {
    hideFilterTL.play();
  };

  const reverseAnimation = () => {
    hideFilterTL.reverse();
    setTimeout(() => {
      gsap.to(".nftContainer", {
        duration: 0.1,
        // ease: "power1.out",
        gridTemplateColumns: "23% 23% 23% 23%",
      });
      hideFilterTL.revert();
    }, 1000);
  };

  useEffect(() => {
    if (location.pathname !== currentPathname) {
      filterElem = document.querySelector("#filter");
      // hideFilterTL.clear();
    }
  }, [location.pathname]);

  useEffect(() => {
    dispatch(fetchDefaultOnchainData());
  }, []);

  return (
    <StyledContainer className="banner-wrapper shape-a">
      { location.pathname === "/market" && (
        <MarketInfo setTab={ setTab } setChangedActivity={ setChangedActivity } />
      ) }
      { location.pathname === "/market" && (
        <StyledMarketTab className="wow fadeInUpBig">
          <div className="tab">
            <div
              className={ `item ${ tab === "items" ? "active" : "" }` }
              onClick={ () => changeTab("items") }
            >
              ITEMS
            </div>
            <div
              className={ `item ${ tab === "activity" ? "active" : "" }` }
              onClick={ () => changeTab("activity") }
            >
              ACTIVITY
            </div>
          </div>
        </StyledMarketTab>
      ) }
      <div id="content" className={ `content ${ !isMobile && location.pathname === "/market" ? 'pad' : '' }` }>
        { !isMobile && location.pathname === "/rarity" && showFilter && (
          <Filter
            galleryMenu={ props.galleryMenu }
            setResetNft={ props.setResetNft }
            resetFilter={ props.resetFilter }
            setResetFilter={ props.setResetFilter }
            tab={ tab }
            setChangedActivity={ setChangedActivity }
            playAnimation={ setShowFilter }
          />
        ) }
        { !isMobile && location.pathname === "/market" && showFilter && (
          <Filter
            galleryMenu={ props.galleryMenu }
            setResetNft={ props.setResetNft }
            resetFilter={ props.resetFilter }
            setResetFilter={ props.setResetFilter }
            tab={ tab }
            setChangedActivity={ setChangedActivity }
            playAnimation={ setShowFilter }
          />
        ) }
        { location.pathname === "/rarity" &&  (
          <Gallery
            setGalleryMenu={ props.setGalleryMenu }
            resetNft={ props.resetNft }
            setResetFilter={ props.setResetFilter }
            openFilter={ openFilter }
            setOpenFilter={ setOpenFilter }
            tab={ "items" }
            changedActivity={ changedActivity }
            setChangedActivity={ setChangedActivity }
            reverseAnimation={ setShowFilter }
            showFilter={ showFilter }
          />
        ) }
        { location.pathname === "/market" && (
          <Gallery
            setGalleryMenu={ props.setGalleryMenu }
            resetNft={ props.resetNft }
            setResetFilter={ props.setResetFilter }
            openFilter={ openFilter }
            setOpenFilter={ setOpenFilter }
            tab={ tab }
            changedActivity={ changedActivity }
            setChangedActivity={ setChangedActivity }
            reverseAnimation={ setShowFilter }
            showFilter={ showFilter }
          />
        ) }
      </div>
      { isMobile && (
        <SlidingPane
          className="sliding"
          overlayClassName="slidingOverlay"
          isOpen={ openFilter }
          onRequestClose={ () => setOpenFilter(false) }
          from={ tab === "activity" ? "left" : "right" }
          hideHeader={ true }
        >
          <Filter
            galleryMenu={ props.galleryMenu }
            setResetNft={ props.setResetNft }
            resetFilter={ props.resetFilter }
            setResetFilter={ props.setResetFilter }
            tab={ tab }
            setChangedActivity={ setChangedActivity }
          />
        </SlidingPane>
      ) }
    </StyledContainer>
  );
}

export default Rarity;
