const reset = require("./Reset.svg").default;
const resetContainer = require("./ResetContainer.svg").default;
const plus = require("./+.svg").default;
const minus = require("./-.svg").default;
const plus2 = require("./+.png").default;
const minus2 = require("./-.png").default;
const search = require("./Search.svg").default;
const rating = require("./Rating.svg").default;
const nftDummy = require("./nftDummy.svg").default;
const pulseIcon = require("./pulseIcon.svg").default;
const pulseIconWhite = require("./pulseIconWhite.svg").default;
const time = require("./time.png").default;
const sort = require("./Sort.svg").default;
const arrowDown = require("./ArrowDown.svg").default;
const list = require("./List.svg").default;
const close = require("./Close.svg").default;
const price = require("./Price.svg").default;
const status = require("./Status.svg").default;
const group = require("./Group.svg").default;
const paste = require("./paste.svg").default;
const warning = require("./warning.svg").default;
const transfer = require("./transfer.svg").default;
const att_prop = require("./att_prop.svg").default;
const eye_prop = require("./eye_prop.svg").default;
const facial_prop = require("./facial_prop.svg").default;
const hair_prop = require("./hair_prop.svg").default;
const mouth_prop = require("./mouth_prop.svg").default;
const neck_prop = require("./neck_prop.svg").default;
const blemishes_prop = require("./blemishes_prop.svg").default;
const ear_prop = require("./ear_prop.svg").default;
const lips_prop = require("./lips_prop.svg").default;
const nose_prop = require("./nose_prop.svg").default;
const loading = require("./loading.gif").default;
const cart = require("./cart.svg").default;
const filter = require("./filter.svg").default;
const menu = require("./menu.svg").default;
const copyright = require("./copyright.svg").default;
const externalLink = require("./externalLink.png").default;
const nftPlaceholder = require("./nftPlaceholder.png").default;
const cancel = require("./Cancel.svg").default;
const filter2 = require("./filter2.svg").default;
const pulseLogoNew = require("./pulsepunks_logo-text-long.png").default;
const pulseNew = require("./pulsepunks_logo.png").default;
const account = require("./account.png").default;
const accountWhite = require("./account-white.svg").default;
const send = require("./Send.svg").default;
const up = require("./up.png").default;
const down = require("./down.png").default
const info = require("./info.png").default
const DAIicon = require("./dai-icon.png").default
const HEXicon = require("./hex-icon.png").default
const INCicon = require("./inc-icon.png").default
const PLSXicon = require("./plsx-icon.png").default
const USDCicon = require("./usdc-icon.png").default
const USDTicon = require("./usdt-icon.png").default
const genericicon = require("./generic-token-icon.png").default

export default {
  reset,
  resetContainer,
  plus,
  plus2,
  minus,
  minus2,
  rating,
  search,
  nftDummy,
  pulseIcon,
  pulseIconWhite,
  time,
  sort,
  arrowDown,
  list,
  close,
  price,
  status,
  group,
  paste,
  warning,
  transfer,
  att_prop,
  eye_prop,
  facial_prop,
  hair_prop,
  mouth_prop,
  neck_prop,
  blemishes_prop,
  ear_prop,
  lips_prop,
  nose_prop,
  loading,
  cart,
  filter,
  menu,
  copyright,
  externalLink,
  nftPlaceholder,
  cancel,
  filter2,
  pulseLogoNew,
  pulseNew,
  account,
  accountWhite,
  send,
  up,
  down,
  info,
  DAIicon,
  HEXicon,
  INCicon,
  PLSXicon,
  USDCicon,
  USDTicon,
  genericicon,
};
