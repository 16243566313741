import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Routes,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isMobile } from 'react-device-detect';
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import { Header } from "./components";
import { Footer } from "./components";
import * as s from "./styles/globalStyles";

import { Home, Rarity, Market, Royalty } from "./pages";

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [resetNft, setResetNft] = useState(false);
  const [galleryMenu, setGalleryMenu] = useState("all");
  const [resetFilter, setResetFilter] = useState(false);

  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(``);
  const [mintStatus, setMintStatus] = useState(0);
  const [mintAmount, setMintAmount] = useState(1);
  const [nftData, setNftData] = useState([
    {
      image: `https://blartech.infura-ipfs.io/ipfs/QmSNAQqbfRw4RS4LdU5qgcC4aXKzombzDxgFumqjzrC9qy/test_images/1.png`,
      name: "PulsePunk #1",
    },
  ]);

  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    API: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
    MAX_ITEM_PER_MINT: 0,
    MAX_ITEM_PER_MINT_NON_VIP: 0,
  });

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  const claimNFTs = async () => {
    let cost = CONFIG.WEI_COST;
    // let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    // let totalGasLimit = String(gasLimit * mintAmount);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);

    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        // gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        setMintStatus(-1);
        setFeedback(`Something went wrong.`);
        setClaimingNft(false);
      })
      .then((receipt) => {
        setMintStatus(1);
        setFeedback(`You've minted ${CONFIG.NFT_NAME}.`);
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
        if (Array.isArray(receipt?.events?.Transfer)) {
          const data = receipt.events.Transfer.map((el) => {
            return {
              image: `https://blartech.infura-ipfs.io/ipfs/QmSNAQqbfRw4RS4LdU5qgcC4aXKzombzDxgFumqjzrC9qy/test_images/${el.returnValues["tokenId"]}.png`,
              name: `Pulse Punk #${el.returnValues["tokenId"]}`,
            };
          });
          setNftData(data);
        } else {
          setNftData([
            {
              image: `https://blartech.infura-ipfs.io/ipfs/QmSNAQqbfRw4RS4LdU5qgcC4aXKzombzDxgFumqjzrC9qy/test_images/${receipt?.events?.Transfer.returnValues["tokenId"]}.png`,
              name: `Pulse Punk #${receipt?.events?.Transfer.returnValues["tokenId"]}`,
            },
          ]);
        }
        $("#NFTModal").modal("toggle");
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) newMintAmount = 1;
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    let mintChecker = 0;
    mintChecker = CONFIG.MAX_ITEM_PER_MINT;
    if (newMintAmount > mintChecker) newMintAmount = mintChecker;
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null)
      dispatch(fetchData(blockchain.account));
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  // Control
  useEffect(() => {
    getConfig();
    getData();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  const router = [
    {
      path: "/",
      element: Home,
    },
    {
      path: "/rarity",
      element: Rarity,
    },
    {
      path: "/market",
      element: Market,
    },
  ];

  return (
    <div className="app">
      {/* Header */}
      <Header />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
        theme="dark"
      />
      {/* Banner */}
      <section className="banner-section">
        <div className="container container2">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="rarity"
              element={
                <Rarity
                  setGalleryMenu={setGalleryMenu}
                  galleryMenu={galleryMenu}
                  setResetNft={setResetNft}
                  resetNft={resetNft}
                  resetFilter={resetFilter}
                  setResetFilter={setResetFilter}
                />
              }
            />
            <Route
              path="market"
              element={
                <Rarity
                  setGalleryMenu={setGalleryMenu}
                  galleryMenu={galleryMenu}
                  setResetNft={setResetNft}
                  resetNft={resetNft}
                  resetFilter={resetFilter}
                  setResetFilter={setResetFilter}
                />
              }
            />
            <Route path="royalty" element={<Royalty />} />

            {/* Using path="*"" means "match anything", so this route
                  acts like a catch-all for URLs that we don't have explicit
                  routes for. */}
            <Route path="*" element={<Home />} />
          </Routes>
        </div>
      </section>
      {
      !isMobile &&
        <Footer />
      }
    </div>
  );
}

export default App;
