import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { StyledContainer, StyledGallery, StyledMarketTab } from "../../styled";
import { Filter, Gallery, MarketInfo } from '../../components';
import { BrowserView, MobileView, isBrowser, isMobile, isTablet } from 'react-device-detect';
import SlidingPane from 'react-sliding-pane';
import "react-sliding-pane/dist/react-sliding-pane.css";
import { gsap } from 'gsap';

function Rarity(props) {
  const location = useLocation();
  const [openFilter, setOpenFilter] = useState(false);
  const [tab, setTab] = useState('items');
  const [changedActivity, setChangedActivity] = useState(false);

  const changeTab = (value) => {
    if (tab !== value) {
      setTab(value);
    }
  };

  return (
    <StyledContainer className="banner-wrapper shape-a">
      {
        location.pathname === '/market' &&
        <MarketInfo setTab={ setTab } setChangedActivity={ setChangedActivity } />
      }
      {
        location.pathname === '/market' &&
        <StyledMarketTab className="wow fadeInUpBig">
          <div className="tab">
            <div className={ `item ${ tab === 'items' ? 'active' : '' }` } onClick={ () => changeTab('items') }>
              ITEMS
            </div>
            <div className={ `item ${ tab === 'activity' ? 'active' : '' }` } onClick={ () => changeTab('activity') }>
              ACTIVITY
            </div>
          </div>
        </StyledMarketTab>
      }
      <div id="content" className="content pad">
        {
          !isMobile
          &&
          <Filter
            galleryMenu={ props.galleryMenu }
            setResetNft={ props.setResetNft }
            resetFilter={ props.resetFilter }
            setResetFilter={ props.setResetFilter }
            tab={ tab }
            setChangedActivity={ setChangedActivity }
            playAnimation={ props.playAnimation }
          />
        }
        <Gallery
          setGalleryMenu={ props.setGalleryMenu }
          resetNft={ props.resetNft }
          setResetFilter={ props.setResetFilter }
          openFilter={ openFilter }
          setOpenFilter={ setOpenFilter }
          tab={ tab }
          changedActivity={ changedActivity }
          setChangedActivity={ setChangedActivity }
          reverseAnimation={ props.reverseAnimation }
        />
      </div>
      {
        isMobile &&
        <SlidingPane
          className="sliding"
          overlayClassName="slidingOverlay"
          isOpen={ openFilter }
          onRequestClose={ () => setOpenFilter(false) }
          from={ tab === "activity" ? "left" : "right" }
          hideHeader={ true }
        >
          <Filter
            galleryMenu={ props.galleryMenu }
            setResetNft={ props.setResetNft }
            resetFilter={ props.resetFilter }
            setResetFilter={ props.setResetFilter }
            tab={ tab }
            setChangedActivity={ setChangedActivity }
          />
        </SlidingPane>
      }
    </StyledContainer>
  );
}

export default Rarity;