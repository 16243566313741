import Images from "../../assets/images";

const initialState = {
  loading: true,
  attributeLoading: true,
  error: false,
  errorMsg: "",
  nfts: [],
  myPP: [],
  page: 1,
  hasNextPage: true,
  nextPage: 1,
  limit: 8,
  totalDocs: 0,
  filters: {
    tokenId: null,
    rankRange: [null, null],
    priceRange: [null, null],
    listed: false,
    attributes: [],
  },
  offers: [],
  myOffers: [],
  myOffersLoading: true,
  marketData: {},
  activityData: [],
  activityHasNextPage: false,
  activityNextPage: 1,
  activityLimit: 10,
  activityFilter: {
    tokenId: null,
    count: 0,
    icon: Images.status,
    label: "Event Type",
    options: [
      {
        value: "Sales",
        code: "Sales",
        checked: true,
      },
      {
        value: "Listings",
        code: "Listing",
        checked: false,
      },
      {
        value: "Offers",
        code: "Offer",
        checked: false,
      },
      {
        value: "Transfers",
        code: "Transfer",
        checked: false,
      },
    ],
  },
  activityLoading: true,
  fetchFilter: {
    // tokenId: 1
    // attributes: {
    // "AttributeCount" : ["1","2"],
    // "Mouth": ["Smile"],
    // "Hair": ["Mohawk"]
    // },
    // listed: true,
    sort: {
      // tokenId: 1,
      // listedPrice: -1,
      rank: 1,
    },
    // rank: {
    //   min: 0,
    //   max: 0,
    // },
    // price: {
    //   min: 0,
    //   max: 0,
    // },
  },
};

const nftsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_NTFS_REQUEST":
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
      };
    case "FETCH_NTFS_SUCCESS":
      return {
        ...state,
        loading: false,
        error: false,
        errorMsg: "",
        totalDocs: action.payload.totalDocs,
        nfts: !action.myPP ? [...state.nfts, ...action.payload.docs] : [],
        myPP: action.myPP ? [...state.myPP, ...action.payload.docs] : [],
        hasNextPage: action.payload.hasNextPage,
        nextPage: action.payload.nextPage,
      };
    case "FETCH_FILTER_SUCCESS":
      let arr = [];
      let a = action.payload.collectionInfo.attributes[0];
      Object.keys(a).forEach((el) => {
        a[el].forEach((opt) => {
          opt.checked = false;
        });
        let icon = Images.group;
        switch (el) {
          case "Attribute Count":
            icon = Images.att_prop;
            break;
          case "Hair":
            icon = Images.hair_prop;
            break;
          case "Eyes":
            icon = Images.eye_prop;
            break;
          case "Facial Hair":
            icon = Images.facial_prop;
            break;
          case "Neck Accessory":
            icon = Images.neck_prop;
            break;
          case "Mouth Prop":
            icon = Images.mouth_prop;
            break;
          case "Mouth":
            icon = Images.lips_prop;
            break;
          case "Blemishes":
            icon = Images.blemishes_prop;
            break;
          case "Ears":
            icon = Images.ear_prop;
            break;
          case "Nose":
            icon = Images.nose_prop;
            break;
          default:
            break;
        }
        arr.push({
          label: el,
          count: 0,
          type: "collapse",
          icon,
          options: a[el],
        });
      });
      let currentState = {
        ...state,
        error: false,
        attributeLoading: false,
        errorMsg: "",
      };
      currentState.filters.attributes = arr;
      return currentState;
    case "FETCH_MARKET_SUCCESS":
      return {
        ...state,
        marketData: {
          items: action.payload.collectionInfo.totalItems,
          owners: action.payload.ownerQty,
          floorPrice: action.payload.floorPrice,
          bestOffer: action.payload.bestOffer,
          listed: action.payload.listedPunk,
          salesCount: action.payload.collectionInfo.salesCount,
          salesVolume: action.payload.collectionInfo.volumeTrade,
          highestSalePrice: action.payload.highestSalePrice,
        },
      };
    case "CHANGE_FILTER":
      return {
        ...state,
        fetchFilter: action.payload,
      };
    case "FETCH_MY_OFFERS":
      return {
        ...state,
        myOffersLoading: true,
      };
    case "FETCH_MY_OFFERS_SUCCESS":
      return {
        ...state,
        myOffers: action.payload,
        myOffersLoading: false,
      };
    case "FETCH_OFFERS_SUCCESS":
      return {
        ...state,
        offers: action.payload.docs,
      };
    case "CLEAR_OFFERS_SUCCESS":
      return {
        ...state,
        offers: [],
      };
    case "FETCH_NTFS_FAILED":
      return {
        ...state,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    case "CHANGE_MULTIPLE_FILTERS":
      let current = { ...state };
      action.payload.forEach((item) => {
        current.filters[item.label] = item.value;
      });
      return current;
    case "CLEAR_NFT":
      return {
        ...state,
        nfts: [],
        myPP: [],
      };
    case "FILTER_ACTIVITY_SUCCESS":
      return {
        ...state,
        activityFilter: action.payload,
      };
    case "FETCH_ACTIVITY_REQUEST":
      return {
        ...state,
        activityLoading: true,
      };
    case "FETCH_ACTIVITY_SUCCESS":
      return {
        ...state,
        activityLoading: false,
        activityData: action.payload.docs,
        activityNextPage: action.payload.nextPage,
        activityHasNextPage: action.payload.hasNextPage,
      };
    default:
      return state;
  }
};

export default nftsReducer;
