import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { connect } from "../redux/blockchain/blockchainActions";
import { utils } from "web3";
import { isMobile } from 'react-device-detect';
import SlidingPane from 'react-sliding-pane';
import "react-sliding-pane/dist/react-sliding-pane.css";
import { InfoTooltip } from "./index";

import images from "../assets/images";
import colors from "../constants/colors";
import { StyledMenu } from '../styled';


function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const pathname = useLocation().pathname;
  const [openMenu, setOpenMenu] = useState(false);

  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    API: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
      RPC_URL: "",
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
    MAX_ITEM_PER_MINT: 0,
    MAX_ITEM_PER_MINT_NON_VIP: 0,
  });

  useEffect(() => {
    if (blockchain) {
      // console.log("blockchain :>> ", blockchain);
    }
    getConfig();
  }, [blockchain]);


  const menus = [
    {
      label: "MINT",
      link: "/",
    },
    {
      label: "RARITY",
      link: "/rarity",
    },
    {
      label: "MARKET",
      link: "/market",
    },
    {
      label: "ROYALTY",
      link: "/royalty",
    },
  ];

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  const checkMetamask = async () => {
    // Check if MetaMask is installed
    // MetaMask injects the global API into window.ethereum
    if (window.ethereum) {
      try {
        // check if the chain to connect to is installed
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: utils.toHex(CONFIG.NETWORK.ID) }], // chainId must be in hexadecimal numbers
        });
      } catch (error) {
        // This error code indicates that the chain has not been added to MetaMask
        // if it is not, then install it into the user MetaMask
        if (error.code === 4902) {
          try {
            await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: utils.toHex(CONFIG.NETWORK.ID),
                  rpcUrls: [CONFIG.NETWORK.RPC_URL],
                  chainName: CONFIG.NETWORK.NAME,
                },
              ],
            });
          } catch (addError) {
            console.error(addError);
          }
        }
        console.error(error);
      }
    } else {
      // if no window.ethereum then MetaMask is not installed
      alert(
        "MetaMask is not installed. Please consider installing it: https://metamask.io/download.html"
      );
    }
  };

  const buttonNYConnect = () => {
    return (
      <>
        <a
          className="ticket-btn lab-btn"
          onClick={ (e) => {
            e.preventDefault();
            dispatch(connect());
          } }
        >
          <span className="connect-wallet">
            <img src={ images.accountWhite } alt="" />
            CONNECT WALLET</span>
        </a>
      </>
    );
  };

  const buttonError = () => {
    return (
      <>
        <a className="lab-btn-error error-meta" onClick={ checkMetamask }>
          <span>
            <img src={ images.warning } alt="" />
            &nbsp;
            { blockchain.errorMsg == "Install Wallet." ? (
              <> INSTALL WALLET </>
            ) : (
              <>
                { blockchain.errorMsg == "Change network to PulseChain." ? (
                  <> SWITCH NETWORK </>
                ) : (
                  <> SOMETHING WENT WRONG </>
                ) }
              </>
            ) }
          </span>
        </a>
      </>
    );
  };

  return (
    <header
      className="header-section"
      style={ { backgroundColor: colors.black2 } }
    >
      <div className="header-bottom">
        <div className="container header-mobile">
          <div className="header-wrapper">
            <div className="logo">
              <a className="header-menu" onClick={ () => open("https://pulsepunks.com") }>
                <img style={ { height: isMobile ? 'auto' : "64px", width: isMobile ? '50vw' : "auto" } } src={ images.pulseLogoNew } />
              </a>
            </div>
            {
              !isMobile &&
              <ul className="menu">
                { menus.map((menu, id) => (
                  <li key={ id }>
                    <a
                      // href={ menu.link }
                      className="header-menu"
                      onClick={ () => navigate(menu.link) }
                      style={
                        {
                          borderBottom: menu.link !== pathname ? "none" : `4px solid `,
                          fontFamily: 'Fugaz One',
                          fontSize: "22px"
                        }
                      }
                    >
                      { menu.label }
                    </a>
                  </li>
                )) }
              </ul>
            }
            <div className="menu-area">
              <div className="cart-ticket">
                { blockchain.account == null && blockchain.errorMsg == "" ? (
                  buttonNYConnect()
                ) : (
                  <>
                    { blockchain.errorMsg != "" ? (
                      buttonError()
                    ) : (
                      <>
                        <a className="lab-btn-reverse">
                          <span style={ { color: colors.purpletext } }>
                            <img src={ images.account } alt="" />{ " " }
                            { blockchain.account.substring(0, 6) +
                              "...." +
                              blockchain.account.substring(
                                blockchain.account.length - 4
                              ) }{ " " }
                          </span>
                        </a>
                      </>
                    ) }
                  </>
                ) }
              </div>

              {
                !isMobile &&
                <div className="header-bar d-lg-none" onClick={ () => setOpenMenu(!openMenu) }>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              }
              {
                isMobile &&
                <div className="mobile-burger" onClick={ () => setOpenMenu(true) }>
                  <img src={ images.menu } alt="" />
                </div>
              }
            </div>
            {
              isMobile &&
              <SlidingPane
                className="sliding"
                overlayClassName="slidingOverlay"
                isOpen={ openMenu }
                onRequestClose={ () => setOpenMenu(false) }
                from="right"
                hideHeader={ true }
              >
                <StyledMenu>
                  <div className="header">
                    <div className="cart-ticket">
                      { blockchain.account == null && blockchain.errorMsg == "" ? (
                        buttonNYConnect()
                      ) : (
                        <>
                          { blockchain.errorMsg != "" ? (
                            buttonError()
                          ) : (
                            <>
                              <a className="lab-btn-reverse">
                                <span style={ { color: colors.purpletext } }>
                                  <img src={ images.account } alt="" />{ " " }
                                  { blockchain.account.substring(0, 6) +
                                    "...." +
                                    blockchain.account.substring(
                                      blockchain.account.length - 4
                                    ) }{ " " }
                                </span>
                              </a>
                            </>
                          ) }
                        </>
                      ) }
                    </div>
                    <img onClick={ () => setOpenMenu(false) } src={ images.close } alt="" />
                  </div>
                  <ul className="mobile-menu">
                    { menus.map((menu, id) => (
                      <li key={ id }>
                        <a
                          // href={ menu.link }
                          className="mobile-header-menu"
                          onClick={ () => { navigate(menu.link); setOpenMenu(false); } }
                          style={ {
                            borderBottom: menu.link !== pathname ? "1px solid #8a8a8a" : `4px solid ${ colors.purpletext }`,
                            color: menu.link !== pathname ? colors.white : colors.purpletext,
                            fontFamily: 'Fugaz One',
                            fontSize: "22px"
                          }
                          }
                        >
                          { menu.label.toUpperCase() }
                        </a>
                      </li>
                    )) }
                  </ul>
                  <span className="join-community">
                    Join Our Community
                  </span>
                  <div className="socials">
                    <a
                      href="https://twitter.com/PunksonPulse"
                      target="_blank"
                      className="mobile-social"
                    >
                      <i className="fa-brands fa-twitter"></i>
                    </a>
                    <a
                      href="https://t.me/punksonpulse"
                      target="_blank"
                      className="mobile-social"
                    >
                      <i className="fa-brands fa-telegram"></i>
                    </a>

                  </div>
                  <span className="copyright fugaz">
                    <img src={ images.copyright } alt="" />
                    PulsePunks 2023

                  </span>
                  <span className="disclaimer fugaz">
                    PulsePunks is in no way affiliated with Larva Labs and/or CryptoPunks, nor any other “Punk” project on or off Pulsechain.

                  </span>
                </StyledMenu>
              </SlidingPane>
            }

          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
