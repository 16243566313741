import styled from "styled-components";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet,
} from "react-device-detect";

import sizes from "./constants/sizes";
import colors from "./constants/colors";

const StyledContainer = styled.div`
  position: relative;
  .content {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 30px;
    display: flex;
    position: absolute;
    width: 100%;
    @media ${sizes.md} {
      display: flex;
      justify-content: center;
    }
  }
  .pad {
    padding-bottom: 30px;
  }
`;

const StyledFilter = styled.div`
  display: flex;
  flex-direction: column;
  padding: 21px;
  width: calc(100vw / 4.5);
  max-width: 400px;
  height: calc(100vh - 174px);
  max-height: 879px;
  /* background-color: rgba(0, 0, 0, 0.75); */
  /* box-shadow: -4px 4px 0 0 #593DF2; */
  background-color: ${colors.black3};
  flex: 0.25;
  position: relative;

  @media ${sizes.md} {
    flex: unset;
    height: 100vh;
    max-height: unset;
    width: 80vw;
    border-left: ${(props) =>
      props.tab === "items" ? "4px solid" : "none"};
    border-right: ${(props) =>
      props.tab === "activity" ? "4px solid" : "none"};
    border-image: linear-gradient(0deg, #4384FB -2.4%, #C431FE 50.09%, #9831FE 89.15%, #FF4895 131.78%) 1;
  }

  * {
    p {
      margin-bottom: 0px;
      font-size: 14px;
    }
  }

  .hide {
    position: absolute;
    /* left: -70px; */
    /* top: 62px; */
    top: 57px;
    left: -85px;
    padding: 2px 20px 6px 20px;
    display: flex;
    align-items: center;
    transform: rotate(90deg);
    background: ${colors.gradient};;
    /* border-radius: 0px 0px 7px 7px; */
    cursor: pointer;
    transition: all 0.25s;
    font-family: "Roboto Condensed";
    font-weight: 400;

    &:hover {
      top: 54px;
      left: -88px;
      padding-top: 8px;
      /* padding: 6px 20px 6px 20px; */
    }

    img {
      width: 20px;
      margin-right: 8px;
    }

    span {
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 12px;
    border-bottom: 1px solid #3C3C3C;
    /* height: 74px; */

    .title {
      font-size: 28px;
      font-family: "Fugaz One";
      background: ${colors.white};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      width: 180px;
      @media ${sizes.md} {
        width: 80px;
      }
    }

    .reset {
      display: flex;
      align-items: center;
      font-size: 10px;
      font-weight: 400;
      color: #9d9d9d;

      .resetBtn {
        margin-left: 10px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.5s;
        width: 30px;
        height: 30px;
        background: ${colors.gradient};
        border-radius: 0px;

        &:hover {
          opacity: 0.7;
        }

        .icon {
          position: absolute;
          width: 22px;
        }
      }
    }
  }

  .filterContainer {
    height: calc(100vh - 251px);
    overflow-y: auto;
    padding-right: 15px;
    margin-top: 12px;

    @media ${sizes.md} {
      height: 100vh;
    }

    .filter {
      padding: 13px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #3C3C3C;
      position: relative;

      .loader {
        margin-left: -18px;
      }

      .icon {
        margin-right: 20px;    
        width: 22px;   
      }

      .icon-collapse {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        left: 0;
        z-index: 1;
        width: 22px;
      }

      input {
        background: transparent;
        border: none;
        color: #ffffff;
        width: 100%;
        /* #D4D0D0 */

        &::placeholder {
          font-family: "Roboto Condensed Light";
          color: #9d9d9d;
          font-size: 14px;
        }
      }

      .range {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 30px 30px;
        @media ${sizes.md} {
          padding: 10px;
        }

        .inputs {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .range-input {
            border: 1px solid ${colors.white2};
            border-radius: 0px;
            width: 75px;
            height: 30px;
            padding: 0px 10px;
            text-align: center;
          }
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          /* Firefox */
          input[type="number"] {
            -moz-appearance: textfield;
          }
          span {
            margin: 0px 14px;
            color: ${colors.white2};
          }
        }
        .apply {
          margin-top: 20px;
          width: 100%;
          background: ${colors.pink};
          /* border-radius: 7px; */
          display: flex;
          justify-content: center;
          align-items: center;
          height: 35px;
          cursor: pointer;
          transition: 0.25s all;
          font-size: 14px;

          &:hover {
            opacity: 0.7;
          }
        }

        .disabled {
          background: #7d7d7d;
          cursor: not-allowed;
        }
      }

      .Collapsible {
        width: 100%;
        .trigger-wrapper {
          position: relative;
        }
        .trigger {
          position: relative;
          width: 100%;
          height: 34px;
          display: flex;
          align-items: center;
          padding-left: 48px;
          cursor: pointer;

          span {
            margin-left: 10px;
            padding: 2px 8px;
            /* border-radius: 3px; */
            background: ${colors.pink};
            font-size: 10px;
          }

          .triggerIcon {
            margin-top: -5px;
            position: absolute;
            right: 0;
            width: 20px;
          }
        }
      }
      .checkbox-wrapper {
        padding: 0px 7px;
        margin-top: 20px;
        max-height: 190px;
        overflow-y: auto;
      }
    }
  }
`;

const StyledGallery = styled.div`
  display: flex;
  flex-direction: column;
  /* background-color: rgba(0, 0, 0, 0.75); */
  background-color: ${colors.black3};
  padding: 21px;
  /* width: 100%; */
  /* width: 65vw; */
  /* width: calc(100vw / 1.55); */
  /* max-width: 1214px; */
  height: calc(100vh - 174px);
  max-height: 879px;
  /* box-shadow: 4px 4px 0 0 #593DF2; */
  transition: all 0.25s ease;
  position: absolute;
  right: 0;
  width: ${(props) => (!props.showFilter ? "100%" : "71%")};
  flex: ${(props) => (!props.showFilter ? 1 : "unset")};

  @media ${sizes.md} {
    flex: unset;
    padding: 15px;
    padding-bottom: 0px;
    /* box-shadow: -4px 4px 0 0 #593DF2; */
    height: calc(100vh - 113px);
    width: 100%;
    margin-left: 0px;
    overflow-y: ${(props) => (props.tab === "activity" ? "scroll" : "none")};
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    max-height: unset;
  }

  .hide {
    position: absolute;
    /* left: -70px; */
    /* top: 62px; */
    top: 60px;
    left: -88px;
    padding: 2px 20px 6px 20px;
    display: flex;
    align-items: center;
    transform: rotate(90deg);
    /*background: ${colors.purple};*/
    background: ${colors.gradient};
    /* border-radius: 0px 0px 7px 7px; */
    cursor: pointer;
    transition: all 0.25s;
    opacity: ${(props) => (!props.showFilter ? 1 : 0)};
    pointer-events: ${(props) => (!props.showFilter ? "auto" : "none")};
    font-family: "Roboto Condensed";
    font-weight: 400;

    &:hover {
      top: 57px;
      left: -91px;
      padding-top: 8px;
    }

    img {
      width: 20px;
      margin-right: 8px;
      transform: rotate(-90deg);
    }

    span {
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 0px;
    margin-top: -5px;
    padding-bottom: 12px;
    border-bottom: 1px solid #3C3C3C;
    width: 100%;

    @media ${sizes.md} {
      padding: 0px 0px 15px 0px;
      display: grid;
      grid-template-columns: 100%;
      width: ${(props) => (props.tab === "activity" ? "200vw" : "100%")};
    }

    .tableHeader {
      display: flex;
      align-items: center;
      width: calc(100% - 20px);
      padding: 0px 30px 0px 20px;

      .desc {
        flex: 1.75;
        text-align: center;
        width: 100%;
      }

      .item {
        flex: 1;
        text-align: center;
        width: 100%;
        &:last-of-type {
          flex: 1.25;
        }

        @media ${sizes.md} {
          flex: 1.5;
      
      .filter {
        .img {
          width: 25px;
        }
      }
          

        }
      }
    }

    .info {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 40px 0px 30px;
      @media ${sizes.md} {
        flex: 1.5;
        padding: 0px 10px 0px 0px;
      }
    }

    .sort {
      position: relative;
      height: 50px;
      display: flex;
      justify-content: flex-end;
      .wrapper {
        position: absolute;
        
        display: flex;
        align-items: center;
        margin-top: 10px;
        span {
          margin-right: 10px;
          color: #9d9d9d;
        }
      }
    }

    .div {
      display: flex;
      align-items: center;

      p {
        margin: 0px 10px 0px 10px;
        font-size: 13px;
        color: #9d9d9d;
      }
    }

    .switch {
      margin-top: -5px;
      display: flex;
      font-size: 22px;
      padding: 4px;
      margin-right: 20px;
      margin-top: 1px;
      border: 1px solid;
      border-image: ${colors.gradient} 1;

      @media ${sizes.md} {
        margin-right: 10px;
        font-size: 18px;
      }

      .switch-menu,
      .switch-menu-active {
        font-family: "Fugaz One";
        font-weight: 400;
        padding: 4px 20px;
        font-size: 24px;
        cursor: pointer;
        transition: all 0.7s;
        line-height: 28px;
        color: rgba(224, 237, 255, 0.5);

        @media ${sizes.md} {
          padding: 0px 10px;
          font-size: 22px;
        }

        &:hover {
          opacity: 0.7;
        }
      }

      .switch-menu-active {
        /*background: ${colors.purple};*/
        background: ${colors.gradient};
        border-radius: 0px;
        color: ${colors.white};
      }
    }

    .count {
      margin-left: 10px;
      color: #9d9d9d;;
      font-size: 13px;
      @media ${sizes.md} {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
        margin-left: 5px;
        font-size: 10px;

        span {
          line-height: unset;
          margin-bottom: -2px;
        }

        .total {
          font-size: 10px;
        }
      }
    }

    .mobile {
      display: grid;
      grid-template-columns: ${(props) =>
        props.loggedIn ? "auto auto auto" : "auto auto"};
      grid-gap: 12px;
      position: relative;

      img {
        width: 26px;
      }
    }

    .myOffer {
      background: ${colors.gradient};
      /* background: transparent; */
      color: #ffffff;
      padding: 6px 8px;
      border-radius: 0px;
      margin-right: 10px;
      cursor: pointer;
      transition: 0.25s all;
      font-family: "Fugaz One";
      font-size: 20px;

      @media ${sizes.md} {
        margin-right: 5px;
        padding: 3px 4px;

      }

      &:hover {
        opacity: 0.7;
      }

      img {
        width: 25px;
      }
    }

    .Dropdown-control {
      cursor: pointer;
      background-color: #1A1922 !important;
      color: #9d9d9d !important;
      font-size: 14px !important;
      padding: 6px 20px !important;
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      border-radius: 0px;
      border-color: #22212A;

      width: 200px;

      img {
        width: 20px;
        height: 20px;
        opacity: 0.5;
        &:hover {
          opacity: 0.9;
        }
      }

      &:hover {
        color: ${colors.white2};
        border-color: #9d9d9d;
      }

      
    }

    .Dropdown-menu {
      margin-top: 5px;
      background-color: ${colors.black3} !important;
      border-color: #9d9d9d;
      z-index: 100;
      transition: all 0.25s;
      padding: 6px 10px !important;

      .is-selected {
        background-color: ${colors.black3} !important;
      }
      .Dropdown-option {
        color: ${colors.white2} !important;
        font-size: 14px !important;

        &:hover {
          background-color: ${colors.black3} !important;
          color: ${colors.purpletext} !important;
        }
      }
    }
  }

  #activityContainer {
    height: calc(100vh - 291px) !important;
    overflow-y: auto !important;
    margin-top: 14px;
    z-index: 1 !important;

    .infinite-scroll-component__outerdiv {
      /* height: 100%; */
    }

    @media ${sizes.md} {
      width: 200vw;
      height: calc(100vh - 180px) !important;
      overflow-y: none !important;
      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }

  .activityContainer {
    padding: 20px 0px;
    position: relative;
    min-height: 300px !important;
    width: 100%;

    @media ${sizes.md} {
      width: ${(props) => (props.tab === "activity" ? "200vw" : "100%")};
    }

    .activityCard {
      width: calc(100% - 20px);
      display: flex;
      align-items: center;
      padding: 15px 22px;
      background: ${colors.black2};
      border: 1px solid;
      border-image: ${colors.gradient} 1;
      border-radius: 0px;
      margin-bottom: 15px;

      &:last-of-type {
        margin-bottom: none;
      }

      .desc {
        flex: 1.75;
        display: flex;
        align-items: center;
        text-align: center;
        width: 100%;

        img {
          width: 60px;
          height: 60px;
          margin-right: 20px;
        }
      }

      .item {
        flex: 1;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        width: 100%;

        &:last-of-type {
          flex: 1.25;
        }

        .external {
          margin-left: 10px;
          cursor: pointer;
          transition: all o.25s;
          width: 18px;

          &:hover {
            opacity: 0.7;
          }
        }
      }

      .blue {
        color: ${colors.purpletext};
        &:hover {
          color: ${colors.pink};
        }
      }

      .disabled {
        pointer-events: none;
        cursor: default;
      }
    }
  }

  #nftContainer {
    height: calc(100vh - 291px) !important;
    overflow-y: auto !important;
    margin-top: 14px;
    z-index: 1 !important;

    .infinite-scroll-component__outerdiv {
      /* height: 100%; */
    }

    @media ${sizes.md} {
      height: calc(100vh - 180px) !important;
      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }

  .nftContainer {
    display: grid;
    grid-template-columns: ${(props) =>
      !props.showFilter ? "18.5% 18.5% 18.5% 18.5% 18.5%" : "23% 23% 23% 23%"};
    /* grid-template-columns: 23% 23% 23% 23%; */
    grid-gap: 20px;
    padding: 20px 30px;
    position: relative;
    min-height: 300px !important;

    @media ${sizes.md} {
      padding: 0px;
      padding-bottom: 20px;
      grid-template-columns: 47.5% 47.5%;
      grid-gap: 16px;
    }

    /* height: calc(100vh - 291px) !important;
    overflow-y: scroll !important;
    margin-top: 14px;
    z-index: 1 !important; */

    .emptyState {
      position: absolute;
      top: 30%;
      left: 0;
      right: 0;
      margin: 0px auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .reset {
        margin-top: 20px;
        width: auto;
        padding: 0px 10px;
        background: ${colors.gradient};
        border-radius: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 35px;
        cursor: pointer;
        transition: 0.25s all;

        &:hover {
          opacity: 0.7;
        }
      }
    }

    .nftCard {
      /* width: calc(100vw / 10); */
      max-width: 189px;
      max-height: 328px;
      background: ${colors.black2};
      padding: 10px;
      border: 1px solid;
      border-image: ${colors.gradient} 1;
      transition: all 0.25s;
      cursor: pointer;
      overflow: hidden;

      @media ${sizes.md} {
        padding: 6px;
      }

      &:hover {
        box-shadow: 0px 0px 8px 8px rgba(0, 0, 0, 0.25);
        transform: scale(1.01);
      }

      p {
        margin: 0px;
      }

      .rank {
        color: ${colors.purpletext};
        margin-bottom: 8px;
        font-weight: 400;
        font-size: 14px;

        label {
          font-size: 16px;
        }

        @media ${sizes.md} {
          font-size: 14px;
          margin-bottom: 4px;

          label {
            font-size: 16px;
          }
        }
      }

      .img-wrapper {
        /* width: 167px;
        height: 167px; */
        background: ${colors.black2};
        align-items: center;
        margin-bottom: 8px;
        @media ${sizes.md} {
          width: 100%;
          height: auto;
          max-height: 167px;
        }

        .nft-img {
          height: 100%;
          width: 100%;
          @media ${sizes.md} {
            /* padding: 6px; */
            justify-content: center;
            width: 100%;
            height: auto;
          }
        }

        
      }

      .name {
        color: #ffffff;
        font-size: 16px;
        font-family: "Roboto Condensed Bold";
        margin-bottom: 4px;

        @media ${sizes.md} {
          font-size: 15px;
          margin-bottom: 6px;
        }
      }

      .amount {
        min-height: 44px;
        display: grid;
        grid-template-columns: 50% 50%;

        .price,
        .offer {
          .label {
            color: #ced3d4;
            font-size: 10px;
            width: 100%;

            img {
              margin-top: -2px;
              margin-left: 1px;
              width: 18px;
              height: 18px;
            }
          }
          .number {
            width: 100%;
            color: #ced3d4;
            font-size: 16px;
          }
        }

        .price {
          text-align: left;
        }

        .offer p {
          text-align: right;
        }
      }
    }
  }

  .overlay2 {
  }
`;

const StyledCheckBox = styled.div`
  display: flex;
  align-items: center;

  .count {
    font-size: 14px;
    color: #8b8b8b;
  }
  /* The container */
  .container {
    display: block;
    position: relative;
    padding-left: 45px;
    margin-bottom: 15px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 15px;
    width: 15px;
    /* background-color: #eee; */
    border: 1px solid #eee;
    border-radius: 0px;
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: ${colors.pink};
    border: 1px solid ${colors.white};
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
  }
`;

const StyledNftDetail = styled.div`
  /* width: 786px; */
  /* height: 500px; */
  background: ${colors.black2};
  border-radius: 1px;
  border: 3px solid;
  border-image: ${colors.gradient} 1;
  position: relative;

  .closeIcon {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  @media ${sizes.md} {
    height: 90vh;
    width: 90vw;
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .general {
    display: flex;
    align-items: center;
    padding: 32px;

    @media ${sizes.md} {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 58px 16px 20px 16px;
    }

    .nft-img,
    .details {
      width: 220px;
      height: 220px;
    }

    .nft-img {
      @media ${sizes.md} {
        margin-right: 0px;
        width: 80vw;
        height: 80vw;
      }
      margin-right: 30px;
    }

    .details {
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      @media ${sizes.md} {
        width: 80vw;
        margin-top: 20px;
        height: auto;
      }

      .name {
        font-size: 30px;
        padding: 4px 16px;
        background: ${colors.black3};
        font-family: "Fugaz One";
        /* border-radius: 10px; */
        @media ${sizes.md} {
          font-size: 26px;
        }
      }

      .rank {
        font-size: 16px;
        color: ${colors.purpletext};
        padding-left: 14px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media ${sizes.md} {
          font-size: 14px;
          margin-top: 10px;
        }

        .transfer {
          width: 18px;
          height: 18px;
          padding: 2px;
          cursor: pointer;
          transition: 0.25s all;
          background: ${colors.black3};
          border: 1px solid ${colors.grey};
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            opacity: 0.7;
          }
        }
      }

      .owned {
        font-size: 12px;
        padding-left: 14px;

        @media ${sizes.md} {
          font-size: 11px;
        }

        .address {
          font-weight: bold;
          font-family: Roboto Condensed;
          text-decoration: underline;
        }
      }

      .pricing {
        padding: 8px 12px;
        background: ${colors.black3};
        /* border-radius: 10px; */

        @media ${sizes.md} {
          margin-top: 14px;
        }

        .amount {
          display: grid;
          grid-template-columns: 47% 47%;
          grid-gap: 12px;

          .price,
          .offer {
            .label {
              width: 100%;
              margin-bottom: 4px;
              display: flex;
              align-items: center;
              color: #ced3d4;
              font-size: 8px;
              font-style: italic;
              img {
                margin-left: 4px;
                width: 12px;
                height: 12px;
              }
            }
            .number {
              width: 100%;
              margin-bottom: 10px;
              font-size: 12px;
              display: flex;
              align-items: center;
              @media ${sizes.md} {
                font-size: 14px;
              }
              img {
                margin-right: 2px;
                width: 20px;
                height: 20px;
              }
            }
          }

          .price {
            margin-left: 4px;
            text-align: left;
          }

          .offer {
            position: relative;

            p {
              text-align: right;
            }

            .number {
              /* margin-left: -4px; */
            }

            .offerList {
              position: absolute;
              width: 12px;
              height: 12px;
              top: 2px;
              right: 0px;
              cursor: pointer;
              transition: all 0.25s;

              &:hover {
                opacity: 0.7;
              }
            }
          }
        }
      }

      .action {
        display: grid;
        grid-template-columns: auto auto;
        grid-gap: 12px;
      }

      .connect {
        margin-bottom: 4px;
        .ticket-btn {
          height: 40px;
          width: 100%;
          line-height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .error {
        .ticket-btn {
          background: #800000;
          box-shadow: 4px 4px 0 0 #000000;

          span {
            color: #ffffff !important;

          }
        }
      }
    }
  }

  .traits-wrapper {
    .tab {
      height: auto;
      background: ${colors.black3};
      padding: 6px 32px;
      width: 100%;

      .menus {
        width: fit-content;
        padding: 6px 0px;
        border-bottom: 4px solid #ffffff;
        font-size: 12px;
      }
    }
    .traits {
      display: grid;
      grid-template-columns: 30.4% 30.4% 30.4%;
      grid-gap: 20px;
      padding: 32px;

      @media ${sizes.md} {
        grid-template-columns: calc(50% - 10px) calc(50% - 10px);
        padding: 16px;
      }

      .trait {
        background: ${colors.black3};
        padding: 10px 20px;
        /* border-radius: 9px; */

        p {
          font-size: 12px;
          margin: 0px;
          @media ${sizes.md} {
            font-size: 12px;
          }
        }

        .label {
          font-weight: bold;
          margin-bottom: 8px;
        }
      }
    }
  }
`;

const StyledBtn = styled.div`
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  transition: 0.25s all;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-family: "Fugaz One";
  /* font-style: italic; */
  padding: 7px 0px;
  height: ${(props) => (props.height ? props.height : "auto")};
  width: ${(props) => (props.width ? props.width : "90px")};
  /* border-radius: 9px; */
  border: 1px solid ${colors.grey};
  background-image: ${(props) =>
    props.disabled
      ? "linear-gradient(64.98deg, #17161E 0%, #6E6D6F 124.66%)"
      : "linear-gradient(92.28deg, #4384FB -2.4%, #C431FE 37.09%, #9831FE 89.15%, #FF4895 131.78%);"};
    
  &:hover {
    ${(props) => !props.disabled && "border: 1px solid #E4E3E3;"}
  }

  color: ${(props) =>
    props.disabled
      ? "#9d9d9d"
      : "#ffffff;"};
  }

  img {
    height: 20px;
    width: auto;
  }
`;

const StyledMarketInfo = styled.div`
  display: grid;
  grid-template-columns: 60% auto;
  grid-gap: 30px;
  margin-bottom: 15px;

  @media ${sizes.md} {
    grid-template-columns: 100%;
    margin-bottom: 10px;
  }

  .center {
    display: ;
    background-color: ${colors.black3};
    padding: 14px 11px 14px 11px;
    width: 100%;
    /* box-shadow: -4px -4px 0 0 #593DF2; */
    .div {
      display: grid;
      grid-template-columns: 20% 25% 25% 30%;
    }
    .value {
      font-size: 24px;
    }
  }

  .left,
  .right {
    /* height: 80px; */
    background-color: ${colors.black3};
    padding: 14px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  p {
    margin: 0;
  }

  .value {
    font-size: 30px;
  }

  img {
    position: absolute;
    margin-left: -32px;
    margin-top: 8px;
    width: 30px;
    height: 30px;

    @media ${sizes.md} {
      margin-top: 4px;
    }
  }

  .label {
    font-family: "Fugaz One";
    font-size: 16px;
    color: #9d9d9d;
    @media ${sizes.md} {
      font-size: 12px;
    }

    img {
      position: relative;
      margin-left: 0px;
      margin-top: -2px;
      width: 18px;
      height: 18px;
  
      @media ${sizes.md} {
        margin-top: -1px;
      }
    }

  }

  .left {
    /* box-shadow: -4px -4px 0 0 #593DF2; */
  }

  .right {
    /* box-shadow: 4px -4px 0 0 #593DF2; */
  }
`;

const StyledDetailModal = styled.div`
  background: ${colors.black2};
  border: 3px solid;
  border-image: ${colors.gradient} 1;
  width: 670px;
  min-height: 300px;
  padding: 18px 40px 30px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  @media ${sizes.md} {
    width: 90vw;
    padding: 18px 20px 30px 20px;
  }

  p {
    margin: 0px;
  }

  .close {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 20px;
    right: 18px;
    cursor: pointer;
    transition: all 0.25s;

    &:hover {
      opacity: 0.7;
    }
  }

  .Dropdown-control {
    cursor: pointer;
    background-color: transparent !important;
    color: #ffffff !important;
    padding: 6px 20px !important;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    border-radius: 0px !important;

    width: 200px;

    @media ${sizes.md} {
      width: 30vw;
    }

    img {
      width: 20px;
      height: 20px;
    }
  }

  .Dropdown-menu {
    width: 100%;
    margin-top: 5px;
    background-color: ${colors.black2} !important;
    z-index: 100;
    transition: all 0.25s;
    padding: 0px 20px !important;
    border-radius: 0px !important;
    width: 200px;
    @media ${sizes.md} {
      width: 30vw;
    }
    .is-selected {
      background-color: ${colors.black2} !important;
      color: ${colors.purple} !important;
    }
    .Dropdown-option {
      color: #ffffff !important;
      padding: 6px 0px;

      &:hover {
        color: ${colors.purple} !important;
        background-color: ${colors.black3} !important;
      }
    }
  }

  .buyModal,
  .offerModal,
  .offerListModal,
  .transferModal {
    width: 100%;
  }

  .labels {
    width: 100%;
    margin-top: 46px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .paste {
      margin-top: -2px;
    }

    @media ${sizes.md} {
      margin-top: 20px !important;
    }
  }

  .buyModal {
    .main {
      width: 100%;
      border-top: 1px solid #646363;
      border-bottom: 1px solid #646363;
      margin: 10px 0px 20px 0px;
      padding: 24px 0px;
      display: flex;
      justify-content: space-between;

      .left {
        display: flex;

        .nft {
          width: 78px;
          height: 78px;
        }

        .detail {
          margin-left: 20px;
          font-size: 14px;
          .name {
            font-weight: bold;
            font-style: italic;
          }
          .royalty {
            font-size: 12px;
          }
        }
      }

      .price {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        img {
          width: 24px;
          height: 24px;
          margin-right: -4px;
        }
        p {
          font-weight: bold;
          font-size: 16px;
          text-align: right;
        }
      }
    }
    .warning {
      p {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: 10px 0px 20px 0px;
        font-size: 12px;
        opacity: 0.8;

        img {
          margin-right: 4px;
          margin-top: -2px;
          width: 22px;
          opacity: 0.8;
        }
      }
    }
  }

  .offerModal {
    .labels {
      width: 100%;
      margin-top: 46px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      .label {
        font-size: 14px;
      }

      .balance {
        font-size: 11px;
        font-style: italic;
        color: #cacaca;
      }
    }

    .offer {
      margin-top: 2px;
      position: relative;

      img {
        position: absolute;
        width: 40px;
        height: 40px;
        top: 9px;
        left: 20px;
      }

      input {
        width: 100%;
        font-size: 18px;
        padding: 12px 25px 12px 80px;
        background: transparent;
        border: 1px solid #ffffff;
        color: #ffffff;
      }
    }

    .main {
      width: 100%;
      border-top: 1px solid #646363;
      border-bottom: 1px solid #646363;
      margin: 20px 0px 20px 0px;
      padding: 24px 0px 24px 0px;
      
      .input {
        display: grid;
        grid-template-columns: auto 63%;
        grid-gap: 10px;

        @media ${sizes.md} {
          grid-template-columns: auto auto;
        }

        input {
          width: 100%;
          font-size: 14px;
          padding: 12px 25px 12px 25px;
          background: transparent;
          border: 1px solid #ffffff;
          color: #ffffff;
          height: 35px;
          
        }
      }
    }

    .warning {
      p {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: 10px 0px 20px 0px;
        font-size: 12px;
        opacity: 0.8;

        img {
          margin-right: 4px;
          margin-top: -2px;
          width: 22px;
          opacity: 0.8;
        }
      }
    }
  }

  .transferModal {
    .labels {
      width: 100%;
      margin-top: 46px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      .label {
        font-size: 14px;
      }
      
      img {
        cursor: pointer;
        transition: 0.25s all;

        &:hover {
          opacity: 0.7;
        }
      }
    }

    .main {
      width: 100%;
      border-top: 1px solid #646363;
      border-bottom: 1px solid #646363;
      margin: 12px 0px 30px 0px;
      padding: 24px 0px;

      p {
        font-size: 14px;
      }

      .input {
        input {
          text-align: center;
          width: 100%;
          font-size: 16px;
          padding: 12px 25px 12px 25px;
          background: transparent;
          border: 1px solid #ffffff;
          color: #ffffff;
          height: 38px;
        }
      }

      p {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: 20px 0px 0px 0px;
        font-size: 12px;
        opacity: 0.8;

        img {
          margin-right: 4px;
          margin-top: -2px;
          width: 22px;
          opacity: 0.8;
        }
      }
    }
  }

  .offerListModal {
    margin-top: 25px;
    border: 1px solid ${colors.black3};
    border-radius: 0px;

    .top-offer {
      background: ${colors.black3};
      border-radius: 0px;
      display: flex;
      align-items: center;
      padding: 15px 29px;

      p {
        font-size: 14px !important;
      }

      img {
        margin: 0px 10px 0px 20px;
        height: 30px;
      }

      .amount {
        font-weight: bold;
      }

      @media ${sizes.md} {
        padding: 14px;
      }
    }

    .offers {
      max-height: 424px;
      min-height: 85px;
      overflow-y: auto;

      .loading {
        width: 100%;
        height: 85px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          height: 20px;
        }
      }

      .offer {
        border-bottom: 1px solid #3C3C3C;
        display: grid;
        grid-template-columns: 25% 25% 25% 25%;
        padding: 20px 29px;

        @media ${sizes.md} {
          padding: 14px;
          grid-template-columns: 33% 33% 33%;
        }

        &:last-of-type {
          border-bottom: none;
        }

        .punk {
          display: flex;
          align-items: center;

          img {
            height: 30px;
            margin-right: 10px;
          }
        }

        .price {
          display: flex;
          align-items: center;
          font-size: 14px;
          @media ${sizes.md} {
            font-size: 12px;
          }
          img {
            margin-right: 10px;
            height: 30px;
          }
        }

        p {
          display: flex;
          align-items: center;
          font-size: 13px;
          @media ${sizes.md} {
            font-size: 10px;
          }
        }

        .cancel {
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .cancel-btn {
            height: 35px;
            font-size: 11px;
            border: 1px solid #ff7373;
            border-radius: 0px;
            color: #ff7373;
            padding: 8px;
            cursor: pointer;
            transition: all 0.5s;

            &:hover {
              background: #ff7373;
              color: #333333;
            }
          }
        }
      }
    }
  }
`;

const StyledRoyalty = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  .inner {
    background-color: ${colors.black3};
    width: 50vw;
    /* height: 76vh; */

    padding: 50px 80px;

    display: flex;
    flex-direction: column;
    align-items: center;

    @media ${sizes.md} {
      width: 100vw;
      /* height: calc(100vh - 110px); */
      padding: 48px 20px 48px 14px;
    }

    .select {
      z-index: 10000;
      margin-bottom: 20px;
    }

    .select-inner__control {
      cursor: pointer;
      background-color: #1A1922 !important;
      color: #9d9d9d !important;
      padding: 6px 20px !important;
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      border-radius: 0px;
      flex-direction: row;
      width: 250px;
      height: 38px;
      border: 1px solid #22212A !important;
      box-shadow: none;
      img {
        margin-top: -3px;
      }

      &:hover {
        color: ${colors.white2} !important;
        border-color: #9d9d9d !important;
      }
    }

    .select-inner__value-container {
      align-items: flex-start;
      padding-left: 60px;
      margin-top: -2px;
      color: #9d9d9d !important;
    }

    .select-inner__single-value {
      color: #9d9d9d !important;
      display: flex;
      flex-direction: row;
      img {
        width: 26px;
        margin-right: 5px;
      }
    }

    .select-inner__indicator-separator {
      display: none;
    }

    .select-inner__indicators {
      margin-top: -6px;
      margin-right: -16px;
    }

    .select-inner__dropdown-indicator {
      color: #9d9d9d !important;
      &:hover {
        color: ${colors.white2} !important;
      }
    }

    .select-inner__menu {
      margin-top: 5px;
      background-color: ${colors.black3} !important;
      z-index: 100;
      border-radius: 0px;
      transition: all 0.25s;
      padding: 6px 0px !important;
      border: 1px solid #9d9d9d !important;
      color: ${colors.white2} !important;

      .select-inner__menu-list {
        width: 100%;

        .select-inner__option {
          background-color: ${colors.black3} !important;
          color: ${colors.white2} !important;
          cursor: pointer;
          &:hover {
            color: ${colors.purpletext} !important;
          }
        }
        .select-inner__option--is-selected {
          background-color: unset !important;
        }

        .custom-option {
          /* background: none; */
          padding-left: 33%;
          display: flex;
          align-items: center;
          img {
            width: 26px;
            margin-right: 4px;
          }
        }
      }
    }

    p {
      margin: 0px;
    }

    .item,
    .timer {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .item {
      border: 1px solid;
      border-image: ${colors.gradient} 1;
      width: 100%;
      padding: 20px;
      margin-bottom: 20px;

      @media ${sizes.md} {
        padding: 20px 10px;
      }

      .title-link {
        color: ${colors.purpletext};
        font-size: 15px;
        cursor: pointer;
        img {
          width: 13px;
          height: auto;
          margin-top: -4px;
          margin-left: 2px;
        }
        @media ${sizes.md} {
          font-size: 12px;
          img {
            width: 11px;
            height: auto;
            margin-top: -5px;
            margin-left: 2px;
          }
        }
      }

      .title {
        color: ${colors.purpletext};
        font-size: 15px;
        @media ${sizes.md} {
          font-size: 12px;
        }
      }

      .value {
        font-size: 24px;

        img {
          margin-top: -2px;
          width: 26px;
        }

        span {
          font-size: 16px;
          color: #ced3d4;
          @media ${sizes.md} {
            font-size: 12px;
          }
        }
      }

      .connect {
        font-family: unset;
        font-size: 14px;
        color: #ced3d4;
        text-align: center;
        font-weight: unset;
        @media ${sizes.md} {
          font-size: 12px;
        }
      }
    }

    .dual {
      width: 100%;
      display: grid;
      grid-template-columns: calc(50% - 10px) calc(50% - 10px);
      grid-gap: 20px;
    }

    .timer {
      width: 100%;
      margin-bottom: 20px;
      text-align: center;
      font-size: 14px;

      .grey {
        color: #ced3d4;
      }

      p {
        font-size: 12px;
        margin-bottom: 10px;
      }

      &:last-of-type {
        margin-top: 20px;
      }

      button,
      input {
        width: 100%;
      }

      input {
        padding: 10px;
        text-align: center;
        background: ${colors.black4};
        color: white;
        border: none;
      }

      button {
        /* padding: 10px; */
        text-align: center;
        font-size: 18px;
        transition: 0.25s all;
        cursor: pointer;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 22px;

        span {
          margin-left: 8px;
          color: ${colors.pink};
        }

        img {
          margin-right: 10px;
          width: 25px;
        }

        &:hover {
          opacity: 0.7;
        }

        &:disabled {
          background: ${colors.black4};
          cursor: not-allowed;
          color: #9d9d9d;
          border: none;
          box-shadow: 4px 4px 0px #333333;

          &:hover {
            opacity: 1;
          }

          span {
            color: #9d9d9d;
          }
        }
      }
    }
  }
`;

const StyledTooltip = styled.div`
  display: inline-block;
  position: relative;
  text-align: left;

  p {
    margin: 0px;
    font-size: 10px;
    font-style: normal;
  }

  .top {
    width: max-content;
    top: -10px;
    left: 60%;
    transform: translate(-50%, -100%);
    padding: 6px 10px;
    color: #ffffff;
    background-color: ${colors.pink};
    font-weight: normal;
    font-size: 13px;
    border-radius: 0px;
    position: absolute;
    z-index: 99999999;
    box-sizing: border-box;
    border: 1px solid transparent;
    box-shadow: 0 1px 8px transparent;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.8s;
  }

  &:hover .top {
    visibility: visible;
    opacity: 1;
  }

  .top i {
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -12px;
    width: 24px;
    height: 12px;
    overflow: hidden;
  }

  .top i::after {
    content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    background-color: ${colors.pink};
    border: 1px solid transparent;
    box-shadow: 0 1px 8px transparent;
  }
`;

const StyledMenu = styled.div`
  position: relative;
  background: ${colors.black2};
  height: 100vh;
  border-left: 4px solid;
  border-image: linear-gradient(0deg, #4384FB -2.4%, #C431FE 50.09%, #9831FE 89.15%, #FF4895 131.78%) 1;
  padding: 20px 24px;
  width: 100%;

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 24px;
    border-bottom: 1px solid #8a8a8a;

    

    .lab-btn,
    .lab-btn-reverse,
    .lab-btn-error {
      display: flex;
      align-items: center;
      justify-content: center;
      /* line-height: 38px; */
    }
  }
  .mobile-menu {
    width: 100%;
    li {
      width: 100%;

      a {
        text-align: center;
        padding: 13px 0px;
        width: 100%;
        border-bottom: 1px solid #3C3C3C;
      }
    }
  }
  

  .join-community {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${colors.white};
    margin-top: 25px;
    font-family: "Roboto Condensed";
    font-size: 14px;
  }

  .socials {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${colors.white};
    margin-top: 15px;
    font-size: 18px;
    .mobile-social {
      padding: 6px 6px;
      margin-left: 6px;
      margin-right: 6px;
    }
   
  }

  .copyright {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #adadad;
    margin-top: 18px;
    font-size: 18px;
    img {
      margin-right: 4px;
    }
  }

  .disclaimer {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #adadad;
    margin-top: 18px;
    font-size: 14px;
  
  }
`;

const StyledMarketTab = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${colors.black3};

  padding: 14px;
  width: 100%;
  margin-bottom: 15px;
  @media ${sizes.md} {
  }

  .tab {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    width: 100%;
    border: 1px solid;
    border-image: ${colors.gradient} 1;
    @media ${sizes.md} {
      margin-left: 0px;
    }

    .item {
      padding: 1px;
      width: 100%;
      /*width: calc(100vh / 1.53);
      width: 300px;*/
      text-align: center;
      border-radius: 0px;
      cursor: pointer;
      transition: 0.25s all;
      font-family: "Fugaz One";
      font-size: 24px;
      color: rgba(224, 237, 255, 0.5);
      

      @media ${sizes.md} {
        width: calc(100vw / 2.2);
        padding: 0px 1px;
        font-size: 21px;
        }
      

      &:hover {
        opacity: 0.7;
        /* background: color: rgba(224, 237, 255, 0.3); */
      }

      &:last-of-type {
        border-radius: 0px 0px 0px 0px;
      }
    }

    .item.active {
      background: ${colors.gradient};
      border-radius: 0px;
      color: ${colors.white};
      &:hover {
        opacity: 1 !important;
      }
    }
  }
`;

const StyledFooter = styled.div`
  position: relative;
  background: ${colors.black2};
  padding: 20px 24px;
  width: 100%;

  .container {
    .footer-wrapper {
      border-top: 1px solid #3C3C3C;

      .socials {
        color: ${colors.white};
        margin-top: 15px;
        padding: 5px 35px 0px 35px;
        margin-bottom: 5px;
           
        .copyright {
          align-items: left;
          color: #adadad;
          margin-top: 18px;
          font-size: 12px;
          display: inline;
          img {
            margin-right: 4px;
          }
          
        }

        .join-community {
          display: flex; 
          justify-content: flex-end;
          color: ${colors.white};
          margin-top: 15px;
          font-family: "Roboto Condensed";
          font-size: 12px;
          display: inline;

          span {
            float: right;
            margin-right: 10px;
            margin-top: 5px;
          }

          a {
            display: inline;
            float: right;
            padding: 4px 7px;
            margin-left: 6px;
            margin-right: 6px;
            color: ${colors.gradient};
          }

        }

      }

    }
   
  }

  



  
`;

export {
  StyledContainer,
  StyledFilter,
  StyledGallery,
  StyledCheckBox,
  StyledNftDetail,
  StyledBtn,
  StyledMarketInfo,
  StyledDetailModal,
  StyledRoyalty,
  StyledTooltip,
  StyledMenu,
  StyledMarketTab,
  StyledFooter,
};
