export default {
  pink: "#BF16E3",
  pink2: "#754A7F",
  purple: "#593DF2",
  purpletext: "#9731FE",
  grey: "#564C4C",
  black: "#0D0B13",
  black2: "#111016",
  black3: "#17161E",
  black4: "#24232F",
  teal: "#1BE2E9",
  white: "#ffffff",
  white2: "#E4E3E3",
  gradient: "linear-gradient(92.28deg, #4384FB -2.4%, #C431FE 37.09%, #9831FE 89.15%, #FF4895 131.78%)",
};
