import React from 'react';

import { StyledBtn } from '../styled';
import Images from '../assets/images';

function customBtn(props) {
  let { label, onClick, disabled, width, isLoading, height } = props;
  return (
    <StyledBtn onClick={ onClick || {} } disabled={ disabled || false } width={ width } height={ height }>
      { isLoading ? <img src={ Images.loading } /> : label || "label" }
    </StyledBtn>
  );
}

export default customBtn;