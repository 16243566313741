import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { StyledFooter } from '../styled';
import images from "../assets/images";
import { InfoTooltip } from "./index";

function Footer() {
  return (
    <StyledFooter>
    {
      !isMobile &&
      <div className="container">
        <div className="footer-wrapper">
          <div className="socials">
            <div className="copyright">
              <img src={ images.copyright } alt="" />
              PulsePunks 2023  &nbsp; | &nbsp; Disclaimer
              &nbsp;
              <InfoTooltip
                text={ `PulsePunks is in no way affiliated with Larva Labs and/or CryptoPunks, nor any other “Punk” project on or off Pulsechain.` }
              />
            </div>
            
            <div className="join-community">
              <a
                href="https://t.me/punksonpulse"
                target="_blank"
                className="social"
                
              >
                <i className="fa-brands fa-telegram"></i>
              </a>
              <a
                href="https://twitter.com/PunksonPulse"
                target="_blank"
                className="social"
              >
                <i className="fa-brands fa-twitter"></i>
              </a>
              <span>
                Join Our Community
              </span>
            </div>    
          </div>   
        </div>    
      </div>
    }  
    </StyledFooter>
  );
}

export default Footer;