import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import ReactDropdown from "react-dropdown";
import "react-dropdown/style.css";
import moment from "moment/moment";
import { useSelector, useDispatch } from "react-redux";
import { utils } from "web3";
import { toast } from "react-toastify";
import { StyledNftDetail, StyledDetailModal } from "../styled";
import { CustomBtn, CustomTooltip } from "./index";
import Images from "../assets/images";
import { fetchOffers, clearOffers, clearNft } from "../redux/nfts/nftsActions";
import { connect } from "../redux/blockchain/blockchainActions";
import truncateOwner from "../helpers/truncateOwner";
import abbreviate from "../helpers/abbreviate";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet,
} from "react-device-detect";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.30)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function nftDetail(props) {
  let { detail, setIsOpen, fetchMore, setNfts } = props;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCancel, setIsLoadingCancel] = useState(false);
  const blockchain = useSelector((state) => state.blockchain);
  const marketData = useSelector((state) => state.nfts.marketData);
  const offers = useSelector((state) => state.nfts.offers);
  const fetchFilter = useSelector((state) => state.nfts.fetchFilter);
  const [modalIsOpen2, setIsOpen2] = useState(false);
  const [modal2Content, setModal2Content] = useState("");
  const [expireTime, setExpireTime] = useState(
    moment(Date.now()).add(1, "days").unix()
  );
  const [transferAddress, setTransferAddress] = useState("");
  const [value, setValue] = useState(1);
  const [balance, setBalance] = useState(0);
  const [offerAmount, setOfferAmount] = useState(0);
  const [listingAmount, setListingAmount] = useState(0);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    MARKET_ADDRESS: "",
    SCAN_LINK: "",
    API: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
    MAX_ITEM_PER_MINT: 0,
    MAX_ITEM_PER_MINT_NON_VIP: 0,
    OWNED_LINK: "",
  });

  const dropdownOptions = [
    {
      label: "1 day",
      value: 1,
    },
    {
      label: "1 week",
      value: 2,
    },
    {
      label: "1 month",
      value: 3,
    },
    {
      label: "1 year",
      value: 4,
    },
  ];
  const [selectedTime1, setSelectedTime1] = useState(dropdownOptions[0]);

  const dropdownOptions2 = [
    {
      label: "1 day",
      value: 1,
    },
    {
      label: "1 week",
      value: 2,
    },
    {
      label: "1 month",
      value: 3,
    },
    {
      label: "1 year",
      value: 4,
    },
  ];
  const [selectedTime2, setSelectedTime2] = useState(dropdownOptions2[0]);

  const openModal = (content) => {
    setModal2Content(content);
  };

  const closeModal = () => {
    setIsOpen2(false);
    setModal2Content("");
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    if (blockchain) {
      getConfig();
    }
  }, [blockchain]);

  useEffect(() => {
    if (modal2Content) {
      setIsOpen2(true);
    }
  }, [modal2Content]);

  useEffect(() => {
    if (detail.bestOffer) {
      dispatch(fetchOffers({ tokenId: detail.tokenId }));
    }
    return () => {
      dispatch(clearOffers());
    };
  }, [detail]);

  useEffect(() => {
    if (blockchain) {
      getAddressBalance();
    }
  }, [blockchain]);



  const reFetch = () => {
    setNfts([]);
    dispatch(clearNft());
    fetchMore(fetchFilter, false, true);
  };

  const buy = (price, nonce) => {
    if (nonce) setIsLoading(true);
    blockchain.marketContract.methods
      .accept(nonce)
      .send({
        // gasLimit: String(totalGasLimit),
        to: CONFIG.MARKET_ADDRESS,
        from: blockchain.account,
        value: blockchain.web3.utils.toWei(price.toString(), "ether"),
      })
      .once("error", (err) => {
        console.log({ err });
        setIsLoading(false);
      })
      .then((receipt) => {
        toast.success("NFT Purchased Successfully");
        //@Nyunk tolong tambahin handler setelah success beli ya
        setIsLoading(false);
        closeModal();
        setIsOpen(false);
        reFetch();
      })
      .catch((err) => {
        setIsLoading(false);
        let message = "";
        if (err.message && err.message.includes("MetaMask Tx Signature: ")) {
          message = err.message.replace("MetaMask Tx Signature: ", "");
        }
        toast.error(message || "Something Went Wrong");
      });
  };

  const BuyModal = () => {
    let name = detail.name.split(" ");
    return (
      <div className="buyModal">
        <div className="labels">
          <p className="label">Item</p>
          <p className="label">Total</p>
        </div>
        <div className="main">
          <div className="left">
            <img
              src={ detail.imageURI }
              alt=""
              onError={ (event) => (event.target.src = Images.nftPlaceholder) }
              className="nft"
            />
            <div className="detail">
              <p className="name">{ name[0] }</p>
              <p className="name">{ name[1] }</p>
              <p className="royalty">Community Royalty: 5.55 %</p>
            </div>
          </div>
          <div className="price">
            <img src={ Images.pulseIcon } alt="" />
            <p>{ detail.listedPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "0" }</p>
          </div>
        </div>
        <div className="warning">
          <p>
            <img src={ Images.warning } alt="" /> Buying price is { abbreviate(percentFloor(detail.listedPrice, marketData.floorPrice), 1) }
            { detail.listedPrice >= marketData.floorPrice ? "% above floor price" : "% below floor price" }
          </p>
        </div>
        <CustomBtn
          width={ "100%" }
          label={
            balance < detail.listedPrice
              ? "INSUFFICIENT FUNDS"
              : "COMPLETE PURCHASE"
          }
          onClick={ () =>
            balance < detail.listedPrice
              ? {}
              : buy(detail.listedPrice, detail.nonce)
          }
          disabled={ balance < detail.listedPrice }
          isLoading={ isLoading }
        />
      </div>
    );
  };

  const getAddressBalance = async () => {
    const currentBalance = await blockchain.web3.eth.getBalance(
      blockchain.account
    );
    setBalance(currentBalance / 10 ** 18);
  };

  const handleOfferChange = (e) => {
    const value = parseFloat(e.target.value.toString().replace(/,/g, ""));
    if (value <= balance) {
      setOfferAmount(value);
    } else {
      if ((balance.toFixed(0) - 100) < 0) {
        setOfferAmount(0);
      } else {
        setOfferAmount(balance.toFixed(0) - 100);
      }
    }
  };

  function getHash(input) {
    var hash = 0,
      len = input.length;
    for (var i = 0; i < len; i++) {
      hash = (hash << 5) - hash + input.charCodeAt(i);
      hash |= 0; // to 32bit integer
    }
    return Math.abs(hash);
  }

  function percentFloor(price, floorPrice) {
    var percent_floor = 0;
    percent_floor = ((price - floorPrice) / floorPrice) * 100;
    return Math.abs(percent_floor);
  }

  const createNonce = () => {
    const nonce =
      Math.floor(Date.now() / 1000).toString() +
      getHash(blockchain.account).toString();

    return nonce;
  };

  const checkApproval = () => {
    return blockchain.smartContract.methods
      .isApprovedForAll(blockchain.account, CONFIG.MARKET_ADDRESS)
      .call({ from: blockchain.account });
  };

  const offer = async () => {
    setIsLoading(true);
    const approval = await checkApproval();
    if (!approval) {
      await blockchain.smartContract.methods
        .setApprovalForAll(CONFIG.MARKET_ADDRESS, true)
        .send({ from: blockchain.account });
    }

    // disini bisa dikasih kaya loader gtu sih nyunk biar orang tau dia bakal trigger metamask kedua
    const offerTupple = [
      1,
      blockchain.account,
      CONFIG.CONTRACT_ADDRESS,
      +detail.tokenId,
      blockchain.web3.utils.toWei(offerAmount.toString(), "ether"),
      createNonce(),
      moment(Date.now()).unix(),
      expireTime,
      0,
    ];
    blockchain.marketContract.methods
      .offer(offerTupple)
      .send({
        // gasLimit: String(totalGasLimit),
        to: CONFIG.MARKET_ADDRESS,
        from: blockchain.account,
        value: blockchain.web3.utils.toWei(offerAmount.toString(), "ether"),
      })
      .once("error", (err) => {
        console.log({ err });
        setIsLoading(false);
      })
      .then((receipt) => {
        toast.success("Offer Placed Successfully");
        setIsLoading(false);
        closeModal();
        setIsOpen(false);
        reFetch();
        //@Nyunk tolong tambahin handler setelah success beli ya
      })
      .catch((err) => {
        setIsLoading(false);
        let message = "";
        if (err.message && err.message.includes("MetaMask Tx Signature: ")) {
          message = err.message.replace("MetaMask Tx Signature: ", "");
        }
        toast.error(message || "Something Went Wrong");
      });
  };

  const cancelOffer = (nonce, id, cancelList) => {
    setIsLoadingCancel(id || true);
    blockchain.marketContract.methods
      .cancel(nonce)
      .send({ from: blockchain.account })
      .once("error", (err) => {
        console.log({ err });
        setIsLoading(false);
      })
      .then((receipt) => {
        toast.success(
          cancelList
            ? "Listing Canceled Successfully"
            : "Offer Canceled Successfully"
        );
        // console.log(receipt);
        setIsLoadingCancel(false);
        closeModal();
        setIsOpen(false);
        reFetch();
        // @nyunk disini harusnya handle dia refetch lagi data detainya biar jadi kereset
      })
      .catch((err) => {
        setIsLoadingCancel(false);
        let message = "";
        if (err.message && err.message.includes("MetaMask Tx Signature: ")) {
          message = err.message.replace("MetaMask Tx Signature: ", "");
        }
        toast.error(message || "Something Went Wrong");
      });
  };

  const acceptOffer = () => {
    setIsLoading(true);
    blockchain.marketContract.methods
      .accept(selectedOffer.nonce)
      .send({
        from: blockchain.account,
      })
      .once("error", (err) => {
        console.log({ err });
        setIsLoading(false);
      })
      .then((receipt) => {
        // console.log(receipt);
        toast.success("Offer Accepted Successfully");
        setIsLoading(false);
        closeModal();
        setIsOpen(false);
        reFetch();
        // @nyunk disini harusnya handle dia refetch lagi data detainya biar jadi kereset
      })
      .catch((err) => {
        setIsLoading(false);
        let message = "";
        if (err.message && err.message.includes("MetaMask Tx Signature: ")) {
          message = err.message.replace("MetaMask Tx Signature: ", "");
        }
        toast.error(message || "Something Went Wrong");
      });
  };

  const paste = () => {
    navigator.clipboard
      .readText()
      .then((clipText) => setTransferAddress(clipText));
  };

  const OfferModal = () => {
    return (
      <div className="offerModal">
        <div className="labels">
          <p className="label">Offer Amount</p>
          <p className="label balance">Balance: { balance.toFixed(0) } PLS</p>
        </div>
        <div className="offer">
          <img src={ Images.pulseIcon } alt="" />
          <input
            type="text"
            className="offer"
            placeholder="0"
            value={ offerAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }
            onChange={ handleOfferChange }
          />
        </div>
        <div className="main">
          <p>Offer Expiration</p>
          <div className="input">
            <ReactDropdown
              options={ dropdownOptions }
              onChange={ (drop) => {
                setSelectedTime1(drop);
                let time;
                drop.value === 1
                  ? (time = moment().add(1, "days").unix())
                  : drop.value === 2
                    ? (time = moment().add(1, "weeks").unix())
                    : drop.value === 3
                      ? (time = moment().add(1, "months").unix())
                      : drop.value === 4
                        ? (time = moment().add(1, "years").unix())
                        : (time = moment().unix());
                setValue(drop.value);
                setExpireTime(time);
              } }
              arrowOpen={ <img src={ Images.arrowDown } alt="" /> }
              arrowClosed={ <img src={ Images.arrowDown } alt="" /> }
              value={ selectedTime1 }
            />
            <input
              type="text"
              value={ moment.unix(expireTime).format("MMM DD, YYYY HH:mm A") }
              disabled
            />
          </div>
        </div>
        <div className="warning">
          <p>
            <img src={ Images.warning } alt="" /> Offering price is { abbreviate(percentFloor(offerAmount, marketData.floorPrice), 1) }
            { offerAmount >= marketData.floorPrice ? "% above floor price" : "% below floor price" }
          </p>
        </div>
        <CustomBtn
          width={ "100%" }
          label={ "MAKE OFFER" }
          onClick={ () => offer() }
          disabled={ offerAmount == 0 }
          isLoading={ isLoading }
          height={ "50px" }
        />
      </div>
    );
  };

  const OfferListModal = () => {
    return (
      <div className="offerListModal">
        <div className="top-offer">
          <p>Top Offer: </p>
          <img src={ Images.pulseIcon } alt="" />
          <p
            className="amount"
            style={ { color: detail.bestOffer >= 5000 ? "#ffffff" : "#7C0A02" } }
          >{ detail.bestOffer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }</p>
        </div>
        <div className="offers">
          { offers.map((offer, id) => {
            return (
              <div key={ id } className="offer">
                { isMobile && (
                  <div className="left">
                    <div className="price">
                      <img src={ Images.pulseIcon } alt="" />
                      <p
                        className="amount"
                        style={ { color: offer.priceInEther >= 5000 ? "#ffffff" : "#7C0A02" } }
                      >{ offer.priceInEther.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }</p>
                    </div>
                    <p className="user">
                      <span className="userId">
                        { truncateOwner(offer.maker) }
                      </span>
                    </p>
                  </div>
                ) }
                { !isMobile && (
                  <div className="price">
                    <img src={ Images.pulseIcon } alt="" />
                    <p
                      className="amount"
                      style={ { color: offer.priceInEther >= 5000 ? "#ffffff" : "#7C0A02" } }
                    >{ offer.priceInEther.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }</p>
                  </div>
                ) }
                { !isMobile && (
                  <p className="user">
                    <span className="userId">{ truncateOwner(offer.maker) }</span>
                  </p>
                ) }
                <p className="expiry">
                  { "Expiry: " + moment(offer.endTime).endOf("day").fromNow() }
                </p>
                { detail.owner?.toLowerCase() ===
                  blockchain.account?.toLowerCase() &&
                  blockchain.account?.toLowerCase() !== offer.maker && (
                    <CustomBtn
                      label={ "ACCEPT" }
                      onClick={ () => {
                        setSelectedOffer(offer);
                        return openModal("acceptOffer");
                      } }
                      disabled={ false }
                    />
                  ) }
                { offer.maker?.toLowerCase() ==
                  blockchain.account?.toLowerCase() && (
                    <div className="cancel">
                      <div
                        className="cancel-btn"
                        onClick={ () => cancelOffer(offer.nonce, id) }
                      >
                        { isLoadingCancel === id ? "Loading" : "Cancel" }
                      </div>
                    </div>
                  ) }
              </div>
            );
          }) }
        </div>
      </div>
    );
  };

  const transfer = () => {
    setIsLoading(true);
    console.log({
      acc: blockchain.account,
      transfer: transferAddress,
      tokenId: detail.tokenId,
    });
    blockchain.smartContract.methods
      .transferFrom(blockchain.account, transferAddress, +detail.tokenId)
      .send({ from: blockchain.account })
      .then((receipt) => {
        toast.success("NFT Transferred Successfully");
        // console.log(receipt);
        setIsLoading(false);
        closeModal();
        setIsOpen(false);
        reFetch();
      })
      .catch((err) => {
        setIsLoading(false);
        let message = "";
        if (err.message && err.message.includes("MetaMask Tx Signature: ")) {
          message = err.message.replace("MetaMask Tx Signature: ", "");
        }
        toast.error(message || "Something Went Wrong");
      });
  };

  const TransferModal = () => {
    return (
      <div className="transferModal">
        <div className="labels">
          <p className="label">Transfer Destination Address</p>
          <img src={ Images.paste } onClick={ paste } alt="" />
        </div>
        <div className="main">
          <div className="input">
            <input
              onChange={ (e) => setTransferAddress(e.target.value) }
              type="text"
              value={ transferAddress }
            />
          </div>
          <p>
            <img src={ Images.warning } alt="" /> Items sent to the wrong address
            cannot be recovered
          </p>
        </div>
        <CustomBtn
          width={ "100%" }
          label={ "TRANSFER" }
          onClick={ () => transfer() }
          disabled={ !transferAddress }
          isLoading={ isLoading }
        />
      </div>
    );
  };

  const handleListingChange = (e) => {
    const value = parseFloat(e.target.value.toString().replace(/,/g, ""));
    setListingAmount(value);

    // if (value <= balance) {
    //   setListingAmount(value);
    // } else {
    //   setListingAmount(balance.toFixed(4) - 0.01);
    // }
  };

  const list = async () => {
    setIsLoading(true);
    const approval = await checkApproval();
    if (!approval) {
      await blockchain.smartContract.methods
        .setApprovalForAll(CONFIG.MARKET_ADDRESS, true)
        .send({ from: blockchain.account });
    }

    // disini bisa dikasih kaya loader gtu sih nyunk biar orang tau dia bakal trigger metamask kedua

    const offerTupple = [
      0,
      blockchain.account,
      CONFIG.CONTRACT_ADDRESS,
      +detail.tokenId,
      blockchain.web3.utils.toWei(listingAmount.toString(), "ether"),
      createNonce(),
      moment(Date.now()).unix(),
      expireTime,
      0,
    ];
    // console.log("offerTupple :>> ", offerTupple);
    blockchain.marketContract.methods
      .offer(offerTupple)
      .send({
        // gasLimit: String(totalGasLimit),
        from: blockchain.account,
      })
      .once("error", (err) => {
        console.log({ err });
        setIsLoading(false);
      })
      .then((receipt) => {
        toast.success("NFT Listed Successfully");
        setIsLoading(false);
        //@Nyunk tolong tambahin handler setelah success jual ya
        closeModal();
        setIsOpen(false);
        reFetch();
        // console.log(receipt);
      })
      .catch((err) => {
        setIsLoading(false);
        let message = "";
        if (err.message && err.message.includes("MetaMask Tx Signature: ")) {
          message = err.message.replace("MetaMask Tx Signature: ", "");
        }
        toast.error(message || "Something Went Wrong");
      });
  };

  const ListModal = () => {
    return (
      <div className="offerModal">
        <div className="labels">
          <p className="label">Listing Price</p>
          <p className="label balance">Balance: { balance.toFixed(0) } PLS</p>
        </div>
        <div className="offer">
          <img src={ Images.pulseIcon } alt="" />
          <input
            type="text"
            className="offer"
            placeholder="0"
            value={ listingAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }
            onChange={ handleListingChange }
          />
        </div>
        <div className="main">
          <p>Listing Expiration</p>
          <div className="input">
            <ReactDropdown
              options={ dropdownOptions2 }
              onChange={ (drop) => {
                setSelectedTime2(drop);
                let time;
                drop.value === 1
                  ? (time = moment().add(1, "days").unix())
                  : drop.value === 2
                    ? (time = moment().add(1, "weeks").unix())
                    : drop.value === 3
                      ? (time = moment().add(1, "months").unix())
                      : drop.value === 4
                        ? (time = moment().add(1, "years").unix())
                        : (time = moment().unix());
                setValue(drop.value);
                setExpireTime(time);
              } }
              arrowOpen={ <img src={ Images.arrowDown } alt="" /> }
              arrowClosed={ <img src={ Images.arrowDown } alt="" /> }
              value={ selectedTime2 }
            />
            <input
              type="text"
              value={ moment.unix(expireTime).format("MMM DD, YYYY HH:mm A") }
              disabled
            />
          </div>
        </div>
        <div className="warning">
          <p>
            <img src={ Images.warning } alt="" /> Listing price is { abbreviate(percentFloor(listingAmount, marketData.floorPrice), 1) }
            { listingAmount >= marketData.floorPrice ? "% above floor price" : "% below floor price" }
          </p>
        </div>
        <CustomBtn
          width={ "100%" }
          label={ "LIST NOW" }
          onClick={ () => list() }
          disabled={ listingAmount == 0 }
          isLoading={ isLoading }
        />
      </div>
    );
  };

  const AcceptOfferModal = () => {
    let name = detail.name.split(" ");
    return (
      <div className="buyModal">
        <div className="labels">
          <p className="label">Item</p>
          <p className="label">Total</p>
        </div>
        <div className="main">
          <div className="left">
            <img
              src={ detail.imageURI }
              onError={ (event) => (event.target.src = Images.nftPlaceholder) }
              alt=""
              className="nft"
            />
            <div className="detail">
              <p className="name">{ name[0] }</p>
              <p className="name">{ name[1] }</p>
              <p className="royalty">Community Royalty: 5.55 %</p>
            </div>
          </div>
          <div className="price">
            <img src={ Images.pulseIcon } alt="" />
            <p>{ selectedOffer.priceInEther.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "0" }</p>
            {/* offer price ntar ya */ }
          </div>
        </div>
        <div className="warning">
          <p>
            <img src={ Images.warning } alt="" /> Offering price is { abbreviate(percentFloor(+selectedOffer.priceInEther, marketData.floorPrice), 1) }
            { +selectedOffer.priceInEther >= marketData.floorPrice ? "% above floor price" : "% below floor price" }
          </p>
        </div>
        <CustomBtn
          width={ "100%" }
          label={ "ACCEPT" }
          onClick={ () => acceptOffer() }
          disabled={ false }
          isLoading={ isLoading }
        />
      </div>
    );
  };

  const checkMetamask = async () => {
    // Check if MetaMask is installed
    // MetaMask injects the global API into window.ethereum
    if (window.ethereum) {
      try {
        // check if the chain to connect to is installed
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: utils.toHex(CONFIG.NETWORK.ID) }], // chainId must be in hexadecimal numbers
        });
      } catch (error) {
        // This error code indicates that the chain has not been added to MetaMask
        // if it is not, then install it into the user MetaMask
        if (error.code === 4902) {
          try {
            await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: utils.toHex(CONFIG.NETWORK.ID),
                  rpcUrls: [CONFIG.NETWORK.RPC_URL],
                  chainName: CONFIG.NETWORK.NAME,
                },
              ],
            });
          } catch (addError) {
            console.error(addError);
          }
        }
        console.error(error);
      }
    } else {
      // if no window.ethereum then MetaMask is not installed
      alert(
        "MetaMask is not installed. Please consider installing it: https://metamask.io/download.html"
      );
    }
  };

  return (
    <StyledNftDetail>
      { isMobile && (
        <img
          onClick={ () => setIsOpen(false) }
          className="closeIcon"
          src={ Images.close }
          alt=""
        />
      ) }
      <div className="general">
        <img
          src={ detail.imageURI }
          onError={ (event) => (event.target.src = Images.nftPlaceholder) }
          alt=""
          className="nft-img"
        />
        <div className="details">
          <div className="name">{ detail.name }</div>
          <div className="rank">
            Rank #{ detail.rank }
            { blockchain.account &&
              detail.owner?.toLowerCase() ===
              blockchain.account?.toLowerCase() && (
                <img
                  onClick={ () => openModal("transfer") }
                  className="transfer"
                  src={ Images.send }
                  alt=""
                />
              ) }
          </div>
          <div className="owned">
            { detail.owner ? (
              <span className="fontRegular">
                Owned by{ " " }
                <a
                  className="address"
                  href={ `${ CONFIG.OWNED_LINK }${ detail.owner }` }
                  target="_blank"
                >
                  { detail.owner.substring(0, 6) +
                    "...." +
                    detail.owner.substring(detail.owner.length - 4) }
                </a>
              </span>
            ) : (
              <span className="fontRegular">Not owned by anyone</span>
            ) }
          </div>
          <div className="pricing">
            <div className="amount">
              <div className="price">
                <p className="label fontRegular">
                  Current Price
                  { detail.saleExpiryDate ? (
                    <CustomTooltip
                      text={ `Sale ends ${ moment(detail.saleExpiryDate).format(
                        "D MMMM, YYYY"
                      ) } at ${ moment(detail.saleExpiryDate).format("h: mm a") }` }
                    />
                  ) : null }
                </p>
                <p className="number">
                  { detail.listedPrice ? (
                    <span>
                      <img src={ Images.pulseIcon } alt="" />
                      { abbreviate(detail.listedPrice, 2) }
                    </span>
                  ) : (
                    <span className="fontRegular">Not listed yet</span>
                  ) }
                </p>
              </div>
              <div className="offer">
                { detail.bestOffer &&
                  detail.owner?.toLowerCase() !==
                  blockchain.account?.toLowerCase() && (
                    <img
                      src={ Images.list }
                      onClick={ () => openModal("offerList") }
                      alt=""
                      className="offerList"
                    />
                  ) }
                <p className="label fontRegular">
                  Best Offer
                  { detail.offerExpiryDate && (
                    <CustomTooltip
                      text={ `Offer ends ${ moment(detail.offerExpiryDate).format(
                        "D MMMM, YYYY"
                      ) } at ${ moment(detail.offerExpiryDate).format(
                        "h: mm a"
                      ) }` }
                    />
                  ) }
                </p>
                <p className="number">
                  { detail.bestOffer ? (
                    <span
                      style={ { color: detail.bestOffer >= 5000 ? "#ffffff" : "#7C0A02" } }
                    >
                      <img src={ Images.pulseIcon } alt="" />
                      { abbreviate(detail.bestOffer, 2) }
                    </span>
                  ) : (
                    <span className="fontRegular">No offer yet</span>
                  ) }
                </p>
              </div>
            </div>
            { blockchain.account === null && blockchain.errorMsg == "" ? (
              <div className="connect">
                <>
                  <a
                    className="ticket-btn lab-btn"
                    onClick={ (e) => {
                      e.preventDefault();
                      dispatch(connect());
                    } }
                  >
                    <span className="connect-wallet">
                      <img src={ Images.accountWhite } alt="" />
                      CONNECT WALLET
                    </span>
                  </a>
                </>
              </div>
            ) : blockchain.errorMsg !== "" ? (
              <div className="connect error">
                <>
                  <a
                    className="ticket-btn lab-btn"
                    onClick={ (e) => {
                      e.preventDefault();
                      // dispatch(connect());
                      checkMetamask();
                    } }
                  >
                    <span className="connect-wallet">
                      <img src={ Images.warning } alt="" />

                      { blockchain.errorMsg == "Install Wallet." ? (
                        <> INSTALL WALLET </>
                      ) : (
                        <>
                          { blockchain.errorMsg ==
                            "Change network to PulseChain." ? (
                            <> SWITCH NETWORK </>
                          ) : (
                            <> SOMETHING WENT WRONG </>
                          ) }
                        </>
                      ) }
                    </span>
                  </a>
                </>
              </div>
            ) : (
              <div className="action">
                { detail.owner?.toLowerCase() !==
                  blockchain.account?.toLowerCase() && (
                    <CustomBtn
                      label={ "BUY NOW" }
                      onClick={ () => (detail?.listed ? openModal("buy") : {}) }
                      disabled={ !detail?.listed }
                      width={ "100%" }
                    />
                  ) }
                {/* { detail.owner?.toLowerCase() ===
                  blockchain.account?.toLowerCase() && (
                  <CustomBtn
                    label={"BUY NOW"}
                    onClick={() => openModal("buy")}
                    disabled={!detail?.listed}
                  />
                )} */}
                { detail.owner?.toLowerCase() ===
                  blockchain.account?.toLowerCase() &&
                  detail?.listed && (
                    <CustomBtn
                      label={ "CANCEL LIST" }
                      onClick={ () => cancelOffer(detail.nonce, true, true) }
                      // disabled={detail.listed}
                      isLoading={ isLoadingCancel }
                      width={ "100%" }
                    />
                  ) }
                { detail.owner?.toLowerCase() ===
                  blockchain.account?.toLowerCase() &&
                  !detail?.listed && (
                    <CustomBtn
                      label={ "LIST NOW" }
                      onClick={ () => openModal("list") }
                      disabled={ detail?.listed }
                      width={ "100%" }
                    />
                  ) }
                { detail.owner?.toLowerCase() ===
                  blockchain.account?.toLowerCase() && (
                    <CustomBtn
                      label={ "SHOW OFFER" }
                      // onClick={ () => detail?.bestOffer ? openModal("offerList") : {} }
                      onClick={ () => openModal("offerList") }
                      disabled={ !detail?.bestOffer }
                      width={ "100%" }
                    />
                  ) }
                { detail.owner?.toLowerCase() !==
                  blockchain.account?.toLowerCase() && (
                    <CustomBtn
                      label={ "MAKE OFFER" }
                      onClick={ () => openModal("offer") }
                      disabled={ false }
                      width={ "100%" }
                    />
                  ) }
              </div>
            ) }
          </div>
        </div>
      </div>
      <div className="traits-wrapper">
        <div className="tab">
          <p className="menus">TRAITS</p>
        </div>
        <div className="traits">
          { Object.keys(detail.attributes).map((attr, id) => {
            return (
              <div key={ id } className="trait">
                <p className="label">{ attr }</p>
                <p className="value fontRegular">{ detail.attributes[attr] }</p>
              </div>
            );
          }) }
        </div>
      </div>
      <Modal
        isOpen={ modalIsOpen2 }
        onAfterOpen={ () => { } }
        // onRequestClose={ () => closeModal() }
        style={ customStyles }
      >
        <StyledDetailModal>
          <span className="label">
            { modal2Content === "buy"
              ? "Complete Checkout"
              : modal2Content === "offer"
                ? "Make An Offer"
                : modal2Content === "offerList"
                  ? "Offers"
                  : modal2Content === "transfer"
                    ? "Complete Transfer"
                    : modal2Content === "list"
                      ? "List Your NFT"
                      : modal2Content === "acceptOffer"
                        ? "Accept Offer"
                        : "" }
          </span>
          <img
            src={ Images.close }
            onClick={ () => (isLoading ? {} : closeModal()) }
            alt=""
            className="close"
          />
          { modal2Content === "buy"
            ? BuyModal()
            : modal2Content === "offer"
              ? OfferModal()
              : modal2Content === "offerList"
                ? OfferListModal()
                : modal2Content === "transfer"
                  ? TransferModal()
                  : modal2Content === "list"
                    ? ListModal()
                    : modal2Content === "acceptOffer"
                      ? AcceptOfferModal()
                      : null }
        </StyledDetailModal>
      </Modal>
    </StyledNftDetail>
  );
}

export default nftDetail;
