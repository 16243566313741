const initialState = {
  loading: false,
  totalSupply: 0,
  isStartMinting: false,
  cost: 0,
  whitelistSignature: null,
  error: false,
  errorMsg: "",
  userBalance: 0,
  vipMax: 10,
  regulerMax: 4,
  currentPrice: 0,
  currentCurrency: "PLS",
  currentState: 0,
  usdContractAddress: "0x",
  maxItemPerMint: 5,
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHECK_DATA_REQUEST":
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_SUCCESS":
      return {
        ...state,
        loading: false,
        totalSupply: action.payload.totalSupply,
        isPresale: action.payload.isPresale,
        isStartMinting: action.payload.isStartMinting,
        userBalance: action.payload.userBalance,
        currentCurrency: action.payload.currentCurrency,
        currentPrice: action.payload.currentPrice,
        currentState: action.payload.currentState,
        usdContractAddress: action.payload.usdContractAddress,
        maxItemPerMint: action.payload.maxItemPerMint,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_FAILED":
      return {
        ...initialState,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    case "CHECK_DATA_DEFAULT_SUCCESS":
      return {
        ...state,
        loading: false,
        totalSupply: action.payload.totalSupply,
        currentCurrency: action.payload.currentCurrency,
        currentPrice: action.payload.currentPrice,
        currentState: action.payload.currentState,
        usdContractAddress: action.payload.usdContractAddress,
        maxItemPerMint: action.payload.maxItemPerMint,
        error: false,
        errorMsg: "",
      };
    default:
      return state;
  }
};

export default dataReducer;
