import Web3 from "web3";
import Web3EthContract from "web3-eth-contract";

import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

const fetchDefaultOnchainDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_DEFAULT_SUCCESS",
    payload: payload,
  };
};

export const fetchData = (address) => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      const nftAbi = await fetch("/config/abi.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      const nftAbiJson = await nftAbi.json();
      const configResponse = await fetch("/config/config.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const erc20Abi = await fetch("/config/erc20.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      const erc20AbiJson = await erc20Abi.json();
      const CONFIG = await configResponse.json();
      const web3 = new Web3("https://rpc.pulsechain.com/");
      Web3EthContract.setProvider(web3.currentProvider);
      const nftContract = new Web3EthContract(
        nftAbiJson,
        CONFIG.CONTRACT_ADDRESS
      );
      let totalSupply = await nftContract.methods.totalSupply().call();
      let userBalance = await nftContract.methods.balanceOf(address).call();

      let currentState = await nftContract.methods.currentBridgeState().call();
      let pricePLS = await nftContract.methods.publicPricePulse().call();
      let priceUSD = await nftContract.methods.publicPriceUSD().call();
      let maxItemPerMint = await nftContract.methods.limitPerTx().call();

      // console.log({ currentState, pricePLS, priceUSD, maxItemPerMint });

      let pricePLSHuman = Web3.utils.fromWei(pricePLS, "ether");
      let priceUSDHuman = Web3.utils.fromWei(priceUSD, "ether");
      // console.log({ pricePLSHuman, priceUSDHuman });

      let currentCurrency = "PLS";
      let usdContractAddress = "0X";

      if (+currentState === 1) {
        usdContractAddress = await store
          .getState()
          .blockchain.smartContract.methods.usdContractAddress()
          .call();
        const usdContract = new Web3EthContract(
          erc20AbiJson,
          usdContractAddress.toString()
        );
        const usdSymbol = await usdContract.methods.symbol().call();
        currentCurrency = usdSymbol;
      }

      // let currentCurrency = currentState == 0 ? "PLS" : usdSymbol;
      let currentPrice = currentState == 0 ? pricePLSHuman : priceUSDHuman;

      dispatch(
        fetchDataSuccess({
          totalSupply,
          userBalance: +userBalance || 0,
          currentCurrency,
          currentPrice,
          currentState,
          usdContractAddress,
          maxItemPerMint,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};

export const fetchDefaultOnchainData = () => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      const nftAbi = await fetch("/config/abi.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      const nftAbiJson = await nftAbi.json();
      const configResponse = await fetch("/config/config.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      const CONFIG = await configResponse.json();
      const web3 = new Web3("https://rpc.pulsechain.com/");
      Web3EthContract.setProvider(web3.currentProvider);
      const nftContract = new Web3EthContract(
        nftAbiJson,
        CONFIG.CONTRACT_ADDRESS
      );
      let totalSupply = await nftContract.methods.totalSupply().call();

      let currentState = await nftContract.methods.currentBridgeState().call();
      let pricePLS = await nftContract.methods.publicPricePulse().call();
      let priceUSD = await nftContract.methods.publicPriceUSD().call();
      let maxItemPerMint = await nftContract.methods.limitPerTx().call();
      let pricePLSHuman = web3.utils.fromWei(pricePLS, "ether");
      let priceUSDHuman = web3.utils.fromWei(priceUSD, "ether");
      let usdContractAddress = await nftContract.methods
        .usdContractAddress()
        .call();

      let currentCurrency = currentState == 0 ? "PLS" : "USD";
      let currentPrice = currentState == 0 ? pricePLSHuman : priceUSDHuman;
      dispatch(
        fetchDefaultOnchainDataSuccess({
          totalSupply,
          currentCurrency,
          currentPrice,
          currentState,
          usdContractAddress,
          maxItemPerMint,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
