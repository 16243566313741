import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Web3, { utils } from "web3";
import Web3EthContract from "web3-eth-contract";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { connect } from "../../redux/blockchain/blockchainActions";
import { fetchData } from "../../redux/data/dataActions";
import * as s from "../../styles/globalStyles";
import abbreviate from "../../helpers/abbreviate";
import images from "../../assets/images";
import colors from "../../constants/colors";

function Home() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(``);
  const [mintStatus, setMintStatus] = useState(0);
  const [mintAmount, setMintAmount] = useState(1);
  const [nftData, setNftData] = useState([
    {
      image: `https://blartech.infura-ipfs.io/ipfs/QmSNAQqbfRw4RS4LdU5qgcC4aXKzombzDxgFumqjzrC9qy/test_images/1.png`,
      name: "Pulse Punk #1",
    },
  ]);
  const [balance, setBalance] = useState(0);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    API: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
      RPC_URL: "",
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
    MAX_ITEM_PER_MINT: 0,
    MAX_ITEM_PER_MINT_NON_VIP: 0,
    IMAGE_LINK: "",
  });

  useEffect(() => {
    if (blockchain.account) {
      getAddressBalance();
    }
  }, [blockchain.account, data.currentState]);

  const getAddressBalance = async () => {
    if (data.currentState == 0) {
      const currentBalance = await blockchain.web3.eth.getBalance(
        blockchain.account
      );
      setBalance(currentBalance / 10 ** 18);
    } else {
      const erc20abi = await fetch("/config/erc20.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      const erc20abijson = await erc20abi.json();

      const usdContract = new Web3EthContract(
        erc20abijson,
        data.usdContractAddress
      );

      const currentBalance = await usdContract.methods
        .balanceOf(blockchain.account)
        .call();
      const currentBalanceHuman = Web3.utils.fromWei(currentBalance, "ether");
      setBalance(currentBalanceHuman);
    }
  };

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  const claimNFTs = async () => {
    // let cost = CONFIG.WEI_COST;
    // let gasLimit = CONFIG.GAS_LIMIT;

    const totalCost = data.currentPrice * mintAmount;
    const totalCostWei = Web3.utils.toWei(totalCost.toString(), "ether");
    // let totalGasLimit = String(gasLimit * mintAmount);
    setClaimingNft(true);
    if (data.currentState == 0) {
      setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
      blockchain.smartContract.methods
        .mintPulse(mintAmount)
        .send({
          // gasLimit: String(totalGasLimit),
          to: CONFIG.CONTRACT_ADDRESS,
          from: blockchain.account,
          value: totalCostWei,
        })
        .once("error", (err) => {
          let message = "";
          if (err.message && err.message.includes("MetaMask Tx Signature: ")) {
            message = err.message.replace("MetaMask Tx Signature: ", "");
          }
          setMintStatus(-1);
          setFeedback("");
          toast.error(message || "Something Went Wrong");
          setClaimingNft(false);
        })
        .then((receipt) => {
          setMintStatus(1);
          setFeedback("");
          setClaimingNft(false);
          dispatch(fetchData(blockchain.account));
          if (Array.isArray(receipt?.events?.Transfer)) {
            const data = receipt.events.Transfer.map((el) => {
              return {
                image: `${CONFIG.IMAGE_LINK}punk${el.returnValues["tokenId"]}@20x.png`,
                name: `PulsePunk #${el.returnValues["tokenId"]}`,
              };
            });
            setNftData(data);
          } else {
            setNftData([
              {
                image: `${CONFIG.IMAGE_LINK}punk${receipt?.events?.Transfer.returnValues["tokenId"]}@20x.png`,
                name: `PulsePunk #${receipt?.events?.Transfer.returnValues["tokenId"]}`,
              },
            ]);
          }
          $("#NFTModal").modal("toggle");
        });
    } else {
      const erc20abi = await fetch("/config/erc20.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      const erc20abijson = await erc20abi.json();
      const usdContract = new Web3EthContract(
        erc20abijson,
        data.usdContractAddress
      );

      usdContract.setProvider(blockchain.provider);

      let allowance = await usdContract.methods
        .allowance(blockchain.account, CONFIG.CONTRACT_ADDRESS)
        .call();
      let allowanceHuman = utils.fromWei(allowance, "ether");
      // console.log({ allowanceHuman });
      let amountToAllow = mintAmount * data.currentPrice;
      if (allowanceHuman < amountToAllow) {
        setFeedback(`Approving token...`);

        let allowInWei = utils.toWei("999999", "ether");
        usdContract.methods
          .approve(CONFIG.CONTRACT_ADDRESS, allowInWei)
          .send({
            to: data.usdContractAddress,
            from: blockchain.account,
          })
          .once("error", (err) => {
            let message = "";
            if (
              err.message &&
              err.message.includes("MetaMask Tx Signature: ")
            ) {
              message = err.message.replace("MetaMask Tx Signature: ", "");
            }
            setMintStatus(-1);
            setFeedback("");
            toast.error(message || "Something Went Wrong");
            setClaimingNft(false);
          })
          .then((receipt) => {
            toast.success(`${data.currentCurrency} Approved`);
            setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
            blockchain.smartContract.methods
              .mintUSD(mintAmount)
              .send({
                // gasLimit: String(totalGasLimit),
                to: CONFIG.CONTRACT_ADDRESS,
                from: blockchain.account,
              })
              .once("error", (err) => {
                let message = "";
                if (
                  err.message &&
                  err.message.includes("MetaMask Tx Signature: ")
                ) {
                  message = err.message.replace("MetaMask Tx Signature: ", "");
                }
                setMintStatus(-1);
                setFeedback("");
                toast.error(message || "Something Went Wrong");
                setClaimingNft(false);
              })
              .then((receipt) => {
                setMintStatus(1);
                setFeedback("");
                setClaimingNft(false);
                dispatch(fetchData(blockchain.account));
                if (Array.isArray(receipt?.events?.Transfer)) {
                  const data = receipt.events.Transfer.map((el) => {
                    return {
                      image: `${CONFIG.IMAGE_LINK}punk${el.returnValues["tokenId"]}@20x.png`,
                      name: `PulsePunk #${el.returnValues["tokenId"]}`,
                    };
                  });
                  setNftData(data);
                } else {
                  setNftData([
                    {
                      image: `${CONFIG.IMAGE_LINK}punk${receipt?.events?.Transfer.returnValues["tokenId"]}@20x.png`,
                      name: `PulsePunk #${receipt?.events?.Transfer.returnValues["tokenId"]}`,
                    },
                  ]);
                }
                $("#NFTModal").modal("toggle");
              });
          });
      } else {
        setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
        blockchain.smartContract.methods
          .mintUSD(mintAmount)
          .send({
            // gasLimit: String(totalGasLimit),
            to: CONFIG.CONTRACT_ADDRESS,
            from: blockchain.account,
          })
          .once("error", (err) => {
            let message = "";
            if (
              err.message &&
              err.message.includes("MetaMask Tx Signature: ")
            ) {
              message = err.message.replace("MetaMask Tx Signature: ", "");
            }
            setMintStatus(-1);
            setFeedback("");
            toast.error(message || "Something Went Wrong");
            setClaimingNft(false);
          })
          .then((receipt) => {
            setMintStatus(1);
            setFeedback("");
            setClaimingNft(false);
            dispatch(fetchData(blockchain.account));
            if (Array.isArray(receipt?.events?.Transfer)) {
              const data = receipt.events.Transfer.map((el) => {
                return {
                  image: `${CONFIG.IMAGE_LINK}punk${el.returnValues["tokenId"]}@20x.png`,
                  name: `PulsePunk #${el.returnValues["tokenId"]}`,
                };
              });
              setNftData(data);
            } else {
              setNftData([
                {
                  image: `${CONFIG.IMAGE_LINK}$punk{receipt?.events?.Transfer.returnValues["tokenId"]}@20x.png`,
                  name: `PulsePunk #${receipt?.events?.Transfer.returnValues["tokenId"]}`,
                },
              ]);
            }
            $("#NFTModal").modal("toggle");
          });
      }
    }
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) newMintAmount = 1;
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    let mintChecker = 0;
    mintChecker = data.maxItemPerMint;
    if (newMintAmount > mintChecker) newMintAmount = mintChecker;
    setMintAmount(newMintAmount);
  };

  const checkMetamask = async () => {
    // Check if MetaMask is installed
    // MetaMask injects the global API into window.ethereum
    if (window.ethereum) {
      try {
        // check if the chain to connect to is installed
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: utils.toHex(CONFIG.NETWORK.ID) }], // chainId must be in hexadecimal numbers
        });
      } catch (error) {
        // This error code indicates that the chain has not been added to MetaMask
        // if it is not, then install it into the user MetaMask
        if (error.code === 4902) {
          try {
            await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: utils.toHex(CONFIG.NETWORK.ID),
                  rpcUrls: [CONFIG.NETWORK.RPC_URL],
                  chainName: CONFIG.NETWORK.NAME,
                },
              ],
            });
          } catch (addError) {
            console.error(addError);
          }
        }
        console.error(error);
      }
    } else {
      // if no window.ethereum then MetaMask is not installed
      alert(
        "MetaMask is not installed. Please consider installing it: https://metamask.io/download.html"
      );
    }
  };

  const getData = () => {
    if (blockchain.account) dispatch(fetchData(blockchain.account));
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  // UI
  const panelNYConnect = () => {
    return (
      <>
        <div className="row gy-5 align-items-center wow fadeInUpBig">
          <div className="col-lg-2 col-12"></div>
          <div className="col-lg-8 col-12">
            <div className="banner-content">
              <div
                style={{
                  maxWidth: "100%",
                  textAlign: "center",
                  backgroundColor: isMobile ? colors.black2 : colors.black3,
                  paddingTop: "35px",
                  fontFamily: "'Poppins', sans-serif",
                  fontSize: "25px",
                  alignItems: "center",
                }}
              >
                <img
                  src={images.pulseNew}
                  alt="banner-img"
                  className=""
                  data-wow-duration="1s"
                  data-wow-delay="0.5s"
                  style={{
                    width: isMobile ? "40%" : "30%",
                  }}
                />
                <h1
                  className=" fugaz"
                  data-wow-duration="1s"
                  data-wow-delay="0.75s"
                  style={{
                    fontSize: isMobile ? "48px" : "56px",
                    marginTop: "10px",
                  }}
                >
                  MINTING IS <span style={{ color: "#BF16E3" }}>LIVE</span>!
                </h1>
                <a
                  className="lab-btn "
                  data-wow-duration="1s"
                  data-wow-delay="1s"
                  style={{ marginBottom: "35px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(connect());
                    getData();
                  }}
                >
                  <span className="connect-wallet">
                    <img src={images.accountWhite} alt="" />
                    CONNECT WALLET
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const panelMintOut = () => {
    return (
      <>
        <div className="row gy-5 align-items-center wow fadeInUpBig">
          <div className="col-lg-2 col-12"></div>
          <div className="col-lg-8 col-12">
            <div className="banner-content">
              <div
                style={{
                  maxWidth: "100%",
                  textAlign: "center",
                  backgroundColor: isMobile ? colors.black2 : colors.black3,
                  paddingTop: "35px",
                  fontFamily: "'Poppins', sans-serif",
                  fontSize: "25px",
                  alignItems: "center",
                }}
              >
                <img
                  src={images.pulseNew}
                  alt="banner-img"
                  className=""
                  data-wow-duration="1s"
                  data-wow-delay="0.5s"
                  style={{
                    width: isMobile ? "40%" : "30%",
                  }}
                />
                <h1
                  className=" fugaz"
                  data-wow-duration="1s"
                  data-wow-delay="0.75s"
                  style={{
                    fontSize: isMobile ? "48px" : "56px",
                    marginTop: "10px",
                  }}
                >
                  MINTING HAS <span style={{ color: "#BF16E3" }}>ENDED</span>!
                </h1>
                <a
                  className="lab-btn "
                  data-wow-duration="1s"
                  data-wow-delay="1s"
                  style={{ marginBottom: "35px" }}
                  onClick={(e) => {navigate("/market")}}
                >
                  <span className="connect-wallet">
                    BUY ON MARKETPLACE
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const panelNotLive = () => {
    return (
      <>
        <div className="row gy-5 align-items-center wow fadeInUpBig">
          <div className="col-lg-2 col-12"></div>
          <div className="col-lg-8 col-12">
            <div className="banner-content">
              <div
                style={{
                  maxWidth: "100%",
                  textAlign: "center",
                  backgroundColor: isMobile ? colors.black2 : colors.black3,
                  paddingTop: "35px",
                  paddingBottom: "30px",
                  fontFamily: "'Poppins', sans-serif",
                  fontSize: "25px",
                }}
              >
                <img
                  src={images.pulseNew}
                  alt="banner-img"
                  className=""
                  data-wow-duration="1s"
                  data-wow-delay="0.5s"
                  style={{
                    width: isMobile ? "40%" : "30%",
                  }}
                />
                <h1
                  className=""
                  data-wow-duration="1s"
                  data-wow-delay="0.75s"
                  style={{ fontSize: "48px", marginTop: "10px" }}
                >
                  MINTING SOON!
                </h1>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const panelError = () => {
    return (
      <>
        <div className="row gy-5 align-items-center">
          <div className="col-lg-2 col-12"></div>
          <div className="col-lg-8 col-12">
            <div className="banner-content">
              <div
                style={{
                  maxWidth: "100%",
                  textAlign: "center",
                  backgroundColor: isMobile ? colors.black2 : colors.black3,
                  paddingTop: "35px",
                  fontFamily: "'Poppins', sans-serif",
                  fontSize: "25px",
                }}
              >
                <img
                  src={images.pulseNew}
                  alt="banner-img"
                  className="wow fadeInUpBig"
                  data-wow-duration="1s"
                  data-wow-delay="0.5s"
                  style={{
                    width: isMobile ? "40%" : "30%",
                  }}
                />
                <h1
                  className="wow fadeInUpBig"
                  data-wow-duration="1s"
                  data-wow-delay="0.75s"
                  style={{ fontSize: "32px", marginTop: "20px" }}
                >
                  {blockchain.errorMsg == "Install Wallet." ? (
                    <>
                      {" "}
                      <span style={{ color: "#593DF2" }}>Wallet</span> Not
                      Installed{" "}
                    </>
                  ) : (
                    <>
                      {blockchain.errorMsg ==
                      "Change network to PulseChain." ? (
                        <> You are on the wrong network </>
                      ) : (
                        <> Something went wrong </>
                      )}
                    </>
                  )}
                </h1>
                <p
                  className="wow fadeInUpBig"
                  data-wow-duration="1s"
                  data-wow-delay="1s"
                  style={{
                    fontSize: "16px",
                    paddingBottom: "35px",
                    width: "100%",
                  }}
                >
                  {blockchain.errorMsg == "Install Wallet." ? (
                    <>
                      {" "}
                      Please Install{" "}
                      <a href="https://youtu.be/yWfZnjkhhhg">
                        <b>Wallet</b>
                      </a>
                      .{" "}
                    </>
                  ) : (
                    <>
                      {blockchain.errorMsg ==
                      "Change network to PulseChain." ? (
                        <>
                          <span
                            onClick={() => checkMetamask()}
                            style={{
                              textDecoration: "underline",
                              marginRight: "10px",
                              cursor: "pointer",
                              color: colors.pink,
                              fontFamily: "Roboto Condensed",
                            }}
                          >
                            Change network
                          </span>
                          <span className="fontRegular">
                            to &nbsp;
                            <b>{CONFIG.NETWORK.NAME}</b>
                          </span>
                          .
                        </>
                      ) : (
                        <> {blockchain.errorMsg} </>
                      )}
                    </>
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const panelMint = () => {
    return (
      <>
        <div className="row gy-5 align-items-center">
          <div className="col-lg-12 col-12">
            <div className="banner-content">
              <div
                className="row"
                style={{
                  maxWidth: "100%",
                  textAlign: "center",
                  backgroundColor: isMobile ? colors.black2 : colors.black3,
                  padding: "25px",
                  fontFamily: "'Poppins', sans-serif",
                  fontSize: "25px",
                  alignItems: "center",
                  margin: "0px",
                }}
              >
                <div className="col-lg-6 col-12 wow fadeInUpBig">
                  <img
                    src="assets/images/banner/preview.gif"
                    alt="banner-img"
                    className=" mintImg"
                    data-wow-duration="1s"
                    data-wow-delay="0.5s"
                    // style={ { height: isMobile ? "auto" : "75% !important" } }
                  />
                </div>
                <div
                  className="col-lg-6 col-12 wow fadeInUpBig"
                  style={{ display: "table" }}
                >
                  <div
                    style={{ display: "table-cell", verticalAlign: "middle" }}
                  >
                    <h1
                      className=""
                      data-wow-duration="1s"
                      data-wow-delay="0.5s"
                      style={{
                        fontSize: isMobile ? "36px" : "60px",
                        fontFamily: "Fugaz One",
                        marginTop: isMobile ? "20px" : "unset",
                      }}
                    >
                      MINT YOUR{" "}
                      <span
                        style={{
                          fontSize: "64px",
                          fontFamily: "Fugaz One",
                          lineHeight: "48px",
                          color: "#BF16E3",
                        }}
                      >
                        PULSEPUNKS
                      </span>
                    </h1>

                    <div style={{ marginTop: "25px" }}>
                      <span
                        className=""
                        data-wow-duration="1s"
                        data-wow-delay="0.75s"
                        style={{ fontSize: isMobile ? "14px" : "18px" }}
                      >
                        {" "}
                        Available NFTs
                      </span>
                      <br />
                      <b
                        className=""
                        data-wow-duration="1s"
                        data-wow-delay="0.75s"
                        style={{ fontSize: "35px" }}
                      >
                        <span style={{ color: "#BF16E3" }}>
                          {CONFIG.MAX_SUPPLY - data.totalSupply}
                        </span>{" "}
                        &nbsp; of &nbsp; {CONFIG.MAX_SUPPLY}
                      </b>

                      {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
                        <>
                          <span
                            className=""
                            data-wow-duration="1s"
                            data-wow-delay="1s"
                            style={{ fontSize: "25px" }}
                          >
                            <b>The Sale has Ended</b>
                          </span>
                          <br />
                          {/* <span
                            className=""
                            data-wow-duration="1s"
                            data-wow-delay="1.1s"
                            style={{ fontSize: "20px" }}
                          >
                            You can still find {CONFIG.NFT_NAME} on{" "}
                            <a
                              style={{
                                textDecoration: "underline",
                                color: "#01b7fc",
                              }}
                              href={CONFIG.MARKETPLACE_LINK}
                            >
                              {CONFIG.MARKETPLACE}
                            </a>
                          </span> */}
                        </>
                      ) : (
                        <>
                          <div
                            className=""
                            data-wow-duration="1s"
                            data-wow-delay="1s"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginTop: "30px",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              className={`${
                                isMobile ? "col-2" : "col-lg-3 col-12"
                              }`}
                              style={{ verticalAlign: "middle" }}
                            >
                              <button
                                disabled={claimingNft ? 1 : 0}
                                style={{
                                  width: "36px",
                                  height: "36px",
                                  border: "none",
                                  background: "transparent",
                                }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  decrementMintAmount();
                                }}
                              >
                                <img
                                  src={images.minus2}
                                  style={{
                                    width: "36px",
                                    height: "36px",
                                  }}
                                  alt=""
                                />
                              </button>
                            </div>
                            <div
                              className={`${
                                isMobile ? "col-6" : "col-lg-6 col-12"
                              }`}
                              style={{
                                fontSize: "36px",
                                marginTop: "-8px",
                                border: "1px solid white",
                              }}
                            >
                              {mintAmount}
                            </div>
                            <div
                              className={`${
                                isMobile ? "col-2" : "col-lg-3 col-12"
                              }`}
                            >
                              <button
                                disabled={claimingNft ? 1 : 0}
                                style={{
                                  width: "36px",
                                  height: "36px",
                                  border: "none",
                                  background: "transparent",
                                }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  incrementMintAmount();
                                }}
                              >
                                <img
                                  src={images.plus2}
                                  style={{
                                    width: "36px",
                                    height: "36px",
                                  }}
                                  alt=""
                                />
                              </button>
                            </div>
                          </div>
                          <div
                            className="row "
                            data-wow-duration="1s"
                            data-wow-delay="1.1s"
                            style={{
                              fontSize: "24px",
                              marginTop: "25px",
                              width: "100%",
                              marginLeft: isMobile ? "0px" : "unset",
                            }}
                          >
                            <div className="col-lg-12 col-12">
                              <span
                                style={{
                                  fontSize: isMobile ? "14px" : "18px",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {" "}
                                Mint Price {data.currentPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                                {data.currentCurrency} per PulsePunk{" "}
                              </span>
                            </div>
                            <div
                              className="col-lg-6 col-6"
                              style={{
                                border: "1px solid white",
                                padding: "2px 0px",
                              }}
                            >
                              <span
                                style={{ fontSize: isMobile ? "14px" : "18px" }}
                              >
                                {mintAmount} x {abbreviate(
                                  Number.parseFloat(
                                    data.currentPrice
                                  ).toFixed(3),
                                  2
                                )}{" "}
                                {data.currentCurrency}{" "}
                              </span>
                            </div>
                            <div
                              className="col-lg-6 col-6"
                              style={{
                                border: "1px solid white",
                                padding: "2px 0px",
                              }}
                            >
                              <span
                                style={{ fontSize: isMobile ? "14px" : "18px" }}
                              >
                                {" "}
                                {abbreviate(
                                  Number.parseFloat(
                                    mintAmount * data.currentPrice
                                  ).toFixed(3),
                                  2
                                )}
                                {/* { Number.parseFloat(
                                  mintAmount * CONFIG.DISPLAY_COST
                                ).toFixed(3) } */}{" "}
                                {data.currentCurrency}{" "}
                              </span>
                            </div>
                          </div>

                          <button
                            className="lab-btn  mintBtn"
                            data-wow-duration="1s"
                            data-wow-delay="1.2s"
                            disabled={
                              claimingNft
                                ? 1
                                : balance < mintAmount * data.currentPrice
                                ? true
                                : 0
                            }
                            style={{
                              width: "100%",
                              margin: "30px 0px 0px 0px",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              claimNFTs();
                              getData();
                            }}
                          >
                            <span style={{ fontSize: "28px" }}>
                              {balance < mintAmount * data.currentPrice
                                ? "INSUFFICIENT FUNDS"
                                : claimingNft
                                ? "PROCESSING"
                                : "MINT"}
                            </span>
                          </button>

                          <div style={{ fontSize: "18px", marginTop: "10px" }}>
                            {mintStatus > 0 ? (
                              <>
                                {" "}
                                <span style={{ color: "#593DF2" }}>
                                  {feedback}
                                </span>{" "}
                              </>
                            ) : (
                              <>
                                {" "}
                                <span style={{ color: "#fff" }}>
                                  {feedback}
                                </span>{" "}
                              </>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const NFTModal = () => {
    return (
      <>
        <div
          className="modal fade"
          id="NFTModal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="NFTModalCenterTitle"
          aria-hidden="true"
          style={{ zIndex: 1050 }}
        >
          <div
            className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable"
            role="document"
          >
            <div
              className="modal-content bg-dark"
              style={{
                backgroundColor: colors.dark3,

                borderStyle: "solid",
                borderRadius: "0",
                borderWidth: 4,
              }}
            >
              <div className="modal-body">
                <div className="container fugaz mb-4">
                  <h3
                    style={{
                      fontStyle: "italic",
                      textAlign: "center",
                      fontSize: isMobile ? 22 : 24,
                      marginTop: "20px",
                    }}
                  >
                    CONGRATS ON MINTING YOUR{" "}
                    <span style={{ color: colors.pink }}>PULSEPUNKS!</span>
                  </h3>
                </div>
                <div className="container">
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: isMobile ? 15 : 17,
                      margin: 0,
                      fontFamily: "sans-serif",
                      width: "100%",
                    }}
                  >
                    Share your PPs on Twitter by clicking{" "}
                    <a
                      href="https://twitter.com/intent/tweet?text=Just%20minted%20the%20FIRST%20Punks%20%23NFT%20on%20Pulsechain%2C%20%40punksonpulse%20!%0A%0AIf%20you%E2%80%99re%20reading%20this%2C%20you%E2%80%99re%20still%20early.%20Grab%20yours%20fast%20at%20app.pulsepunks.com%20%26%20be%20a%20part%20of%20history%20today%20!"
                      target="_blank"
                      className="success-link"
                      style={{
                        fontSize: isMobile ? 15 : 17,
                        fontFamily: "sans-serif",
                      }}
                    >
                      here
                    </a>
                  </p>
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: isMobile ? 15 : 17,
                      fontFamily: "sans-serif",
                      width: "100%",
                    }}
                  >
                    You can see your PPs on the{" "}
                    <span
                      className="success-link"
                      onClick={() => {
                        $("#NFTModal").modal("hide");
                        navigate("/rarity");
                      }}
                    >
                      Rarity
                    </span>{" "}
                    or{" "}
                    <span
                      className="success-link"
                      onClick={() => {
                        $("#NFTModal").modal("hide");
                        navigate("/market");
                      }}
                    >
                      Market
                    </span>{" "}
                    page under the My PPs tab
                  </p>
                </div>
                <div
                  className="flex row nft-modal-scroll"
                  style={{
                    padding: "0px 40px",
                    overflowY: "scroll",
                    height: "28rem",
                    flexWrap: "wrap",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  {nftData.map((nft, i) => {
                    return (
                      <div
                        onClick={() => {
                          $("#NFTModal").modal("hide");
                          navigate("/rarity");
                        }}
                        key={i}
                        className="card bg-dark"
                        style={{
                          width: "auto",
                          height: "auto",
                          border: "none",
                          cursor: "pointer",
                        }}
                      >
                        <img
                          className="card-img-top"
                          style={{
                            width: "100%",
                            height: "auto",
                            maxHeight: "15rem",
                            maxWidth: "15rem",
                          }}
                          src={nft.image}
                          alt="Card image cap"
                          onError={(event) =>
                            (event.target.src = images.nftPlaceholder)
                          }
                        />
                        <div className="card-body">
                          <p
                            className="card-text"
                            style={{
                              marginTop: "-5px",
                              justifyContent: "center",
                              textAlign: "center",
                              width: "100%",
                            }}
                          >
                            {nft.name}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  // Control
  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <div className="banner-wrapper shape-a">
      {blockchain.account == null && blockchain.errorMsg == "" ? (
        // panelNYConnect()
        panelMintOut()
      ) : (
        <>
          {blockchain.errorMsg != ""
            ? panelMintOut()
            : // : data.isStartMinting
            true
            ? panelMintOut()
            : panelMintOut()}
        </>
      )}
      {NFTModal()}
    </div>
  );
}

export default Home;
