import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Collapsible from 'react-collapsible';
import ContentLoader from "react-content-loader";
import { useDebounce } from "use-debounce";
import { BrowserView, MobileView, isBrowser, isMobile, isTablet } from 'react-device-detect';

import { StyledFilter } from '../styled';
import Images from "../assets/images";
import {
  fetchFilter,
  fetchNfts,
  changeFilter as changeFilterReducer,
  changeMultipleFilters,
  clearNft,
  fetchActivity,
  changeListed,
  changeActivityFilter
} from "../redux/nfts/nftsActions";
import { CustomCheckbox } from "./index";

function Filter(props) {
  let {
    setResetNft,
    galleryMenu,
    resetFilter,
    setResetFilter,
    tab,
    setChangedActivity,
    playAnimation
  } = props;
  const dispatch = useDispatch();
  const previousController = useRef();
  const nftsData = useSelector((state) => state.nfts);
  const attributeLoading = useSelector((state) => state.nfts.attributeLoading);
  const filtersData = useSelector((state) => state.nfts.filters);
  const appliedFilter = useSelector((state) => state.nfts.fetchFilter);
  const blockchain = useSelector((state) => state.blockchain);
  const activityFilter = useSelector((state) => state.nfts.activityFilter);
  const [localFilter, setLocalFilter] = useState(appliedFilter);
  const [attributes, setAttributes] = useState(filtersData.attributes);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [appliedRank, setAppliedRank] = useState(filtersData.rankRange);
  const [minRank, setMinRank] = useState(filtersData.rankRange[0]);
  const [maxRank, setMaxRank] = useState(filtersData.rankRange[1]);
  const [appliedPrice, setAppliedPrice] = useState(filtersData.priceRange);
  const [minPrice, setMinPrice] = useState(filtersData.priceRange[0]);
  const [maxPrice, setMaxPrice] = useState(filtersData.priceRange[1]);
  const [listed, setListed] = useState(filtersData.listed);
  const [tokenId, setTokenId] = useState(filtersData.tokenId);
  const [tokenIdActivity, setTokenIdActivity] = useState(activityFilter.tokenId);
  const [debouncedTokenId] = useDebounce(tokenId, 1500);
  const [debouncedTokenIdActivity] = useDebounce(tokenIdActivity, 1500);
  const [count, setCount] = useState(1);
  const [tempActivityFilter, setTempActivityFilter] = useState({
    count: 0,
    icon: Images.status,
    label: "Event Type",
    options: [
      {
        value: 'Sales',
        code: 'Sales',
        checked: true
      },
      {
        value: 'Listings',
        code: 'Listing',
        checked: false
      },
      {
        value: 'Offers',
        code: 'Offer',
        checked: false
      },
      {
        value: 'Transfers',
        code: 'Transfer',
        checked: false
      },
    ]
  });

  useEffect(() => {
    if (filtersData.attributes.length === 0) {
      dispatch(fetchFilter());
    }
  }, []);

  useEffect(() => {
    if (resetFilter) {
      resetAllFilter();
      setResetFilter(false);
    }
  }, [resetFilter]);

  const changePreviousController = value => {
    previousController.current = value;
  };

  const reHitNft = () => {
    let galleryEl = document.getElementById('nftContainer');
    galleryEl && galleryEl.scroll && galleryEl.scroll({ top: 0, behavior: 'smooth' });
    if (previousController.current) {
      previousController.current.abort();
    }
    setResetNft(true);
    dispatch(clearNft());
    dispatch(changeFilterReducer(localFilter));
    let params = {
      page: 1,
      limit: nftsData.limit,
    };
    if (galleryMenu === "mine" || location.search.includes("myPP")) params.address = blockchain.account;
    if (galleryMenu === "offer") params.offers = blockchain.account;
    dispatch(fetchNfts(params, localFilter, false, changePreviousController));
    setResetNft(false);
    setCount(count + 1);
  };

  useEffect(() => {
    if (localFilter !== appliedFilter) {
      reHitNft();
    }
  }, [localFilter]);

  useEffect(() => {
    setAttributes(filtersData.attributes);
  }, [filtersData.attributes]);

  useEffect(() => {
    setTokenId(filtersData.tokenId);
  }, [filtersData.tokenId]);

  useEffect(() => {
    setListed(filtersData.listed);
  }, [filtersData.listed]);

  useEffect(() => {
    setMinRank(filtersData.rankRange[0]);
    setMaxRank(filtersData.rankRange[1]);
    setAppliedRank[filtersData.rankRange];
  }, [filtersData.rankRange]);

  useEffect(() => {
    setMinPrice(filtersData.priceRange[0]);
    setMaxPrice(filtersData.priceRange[1]);
    setAppliedPrice[filtersData.priceRange];
  }, [filtersData.priceRange]);

  const resetAllFilter = () => {
    if (tab != 'activity' || location.pathname != "/market") {
      setMinRank("");
      setMaxRank("");
      setAppliedRank(["", ""]);
      setMinPrice("");
      setMaxPrice("");
      setAppliedPrice(["", ""]);
      setTokenId("");
      setListed(false);
      let resettedAttributes = filtersData.attributes;
      resettedAttributes.forEach(att => {
        att.options.forEach(opt => {
          opt.checked = false;
        });
      });
      setAttributes(resettedAttributes);
      dispatch(changeMultipleFilters(
        [
          {
            label: "attributes",
            value: resettedAttributes
          },
          {
            label: "tokenId",
            value: null
          },
          {
            label: "listed",
            value: false
          },
          {
            label: "rankRange",
            value: [null, null]
          },
          {
            label: "priceRange",
            value: [null, null]
          }
        ]
      ));
      let applied = { ...appliedFilter };
      delete applied.tokenId;
      delete applied.rank;
      delete applied.listed;
      delete applied.price;
      delete applied.attributes;
      setLocalFilter({ ...applied });
    } else if (tab == 'activity' && location.pathname === "/market") {
      setTokenIdActivity("");
      let temp = {
        tokenId: null,
        count: 0,
        icon: Images.status,
        label: "Event Type",
        options: [
          {
            value: "Sales",
            code: "Sales",
            checked: true,
          },
          {
            value: "Listings",
            code: "Listing",
            checked: false,
          },
          {
            value: "Offers",
            code: "Offer",
            checked: false,
          },
          {
            value: "Transfers",
            code: "Transfer",
            checked: false,
          },
        ],
      };
      dispatch(changeActivityFilter({}, temp));
      setChangedActivity(true);
    }
  };

  const changeLocalListed = value => {
    setListed(value);
    dispatch(changeMultipleFilters([{ label: "listed", value: value ? 1 : false }]));
    compareFilter('listed', value);
  };

  const changeAppliedRange = key => {
    switch (key) {
      case 'rank':
        setAppliedRank([minRank, maxRank]);
        dispatch(changeMultipleFilters([{ label: 'rankRange', value: [minRank, maxRank] }]));
        compareFilter('rank', [minRank, maxRank]);
        break;
      case 'price':
        setAppliedPrice([minPrice, maxPrice]);
        dispatch(changeMultipleFilters([{ label: 'priceRange', value: [minPrice, maxPrice] }]));
        compareFilter('price', [minPrice, maxPrice]);
        break;
      default:
        break;
    }
  };

  const changeLocalAttributes = (attr, value, tempFlag) => {
    if (tempFlag) {
      let temp = activityFilter;
      console.log('activityFilter', activityFilter);
      temp.options.forEach(opt => {
        if (opt.value === value) {
          opt.checked = !opt.checked;
        }
      });
      dispatch(changeActivityFilter({}, temp));
      setChangedActivity(true);
    } else {
      let arr = [...filtersData.attributes];
      arr.forEach(att => {
        if (att.label === attr) {
          att.options.forEach(opt => {
            if (opt.value === value) {
              opt.checked = !opt.checked;
            }
          });
        }
      });
      setAttributes([...arr]);
      dispatch(changeMultipleFilters([{ label: "attributes", value: arr }]));
      setTimeout(() => {
        compareFilter('attributes', [...arr]);
      }, 1500);
    }
  };

  const compareFilter = (key, value) => {
    let applied = { ...appliedFilter } || {};
    switch (key) {
      case 'tokenId':
        value ? applied.tokenId = value : delete applied["tokenId"];
        setLocalFilter({ ...applied });
        break;
      case 'rank':
        value[0] >= 0 && value[1] > 0 ? applied.rank = { min: value[0], max: value[1] } : delete applied.rank;
        setLocalFilter({ ...applied });
        break;
      case 'listed':
        value ? applied.listed = 1 : delete applied.listed;
        setLocalFilter({ ...applied });
        break;
      case 'price':
        value[0] >= 0 && value[1] > 0 ? applied.price = { min: value[0], max: value[1] } : delete applied.rank;
        setLocalFilter({ ...applied });
        break;
      case 'attributes':
        let localAttributes = {};
        value.forEach(att => {
          if (att.options) {
            let opts = [];
            att.options.forEach(opt => {
              if (opt.checked) {
                // opts.push(opt.value.split(' ').join(''));
                opts.push(opt.value);
              }
            });
            if (opts.length > 0) {
              localAttributes[att.label.split(' ').join('')] = opts;
            }
          }
        });
        if (Object.keys(localAttributes).length > 0) {
          applied = { ...applied, attributes: localAttributes };
        } else {
          delete applied["attributes"];
        }
        setLocalFilter({ ...applied });
        // changeFilterReducer(applied);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (debouncedTokenId !== filtersData.tokenId) {
      console.log('debouncedTokenId', debouncedTokenId);
      dispatch(changeMultipleFilters([{ label: "tokenId", value: debouncedTokenId || null }]));
      compareFilter('tokenId', debouncedTokenId);
    }
  }, [debouncedTokenId]);

  useEffect(() => {
    if (debouncedTokenIdActivity !== activityFilter.tokenId) {
      let temp = activityFilter;
      temp.tokenId = debouncedTokenIdActivity || null;
      dispatch(changeActivityFilter({}, temp));
      setChangedActivity(true);
    }
  }, [debouncedTokenIdActivity]);

  const countFilter = (attr) => {
    let count = 0;
    filtersData.attributes.forEach(el => {
      if (el.label === attr) {
        el.options && el.options.forEach(opt => {
          if (opt.checked) count++;
        });
      }
    });
    return count;
  };

  const countFilter2 = (attr) => {
    let count = 0;
    activityFilter.options.forEach(opt => {
      if (opt.checked) count++;
    });
    return count;
  };

  const checkRank = () => {
    return minRank && (maxRank > minRank);
  };

  const checkPrice = () => {
    return minPrice && (maxPrice > minPrice);
  };

  const renderRangeInfo = (value1, value2) => {
    if (parseInt(value1) > 0 || parseInt(value2) > 0) {
      return (
        <span className="info fontLight">{ value1 } - { value2 }</span>
      );
    } else {
      return null;
    }
  };

  return (
    <StyledFilter id="filter" className={ `${ !isMobile ? "wow fadeInUpBig" : "" }` } tab={ tab } >
      {
        !isMobile &&
        <div onClick={ () => playAnimation(false) || null } className="hide">
          <img src={ Images.cancel } alt="" />
          <span>Hide Filter</span>
        </div>
      }
      <div className="header">
        <p className='title'>FILTERS</p>
        <div className="reset fontLight" onClick={ resetAllFilter }>
          Reset Filters
          <div className="resetBtn">
            {/* <img src={ Images.resetContainer } alt="" srcSet="" /> */ }
            <img className='icon' src={ Images.reset } alt="" srcSet="" />
          </div>
        </div>
      </div>
      {
        attributeLoading
          ?
          <div className="filterContainer">
            {
              [1, 2, 3, 4, 5, 6, 7].map((el, id) => (
                <div key={ id } className="filter">
                  <ContentLoader
                    speed={ 2 }
                    width={ 227 }
                    height={ 30 }
                    viewBox="0 0 227 34"
                    backgroundColor="#7d7d7d"
                    foregroundColor="#ffffff"
                    className='loader'
                  >
                    <rect x="60" y="7" rx="0" ry="0" width="89" height="20" />
                    <circle cx="17" cy="17" r="17" />
                  </ContentLoader>
                </div>
              ))
            }
          </div>
          :
          tab === 'activity' && location.pathname === "/market"
            ?
            <div className="filterContainer">
              <div className="filter">
                <img className='icon' src={ Images.search } alt="" />
                <input type="text" value={ tokenIdActivity } onChange={ e => setTokenIdActivity(e.target.value) } placeholder={ "Filter by Token ID..." } />
              </div>
              <div className="filter">
                <Collapsible
                  open
                  trigger={ <div className='trigger-wrapper'> <img className='icon-collapse' src={ activityFilter.icon } alt="" /> <p className='trigger'>{ activityFilter.label.toUpperCase() } { countFilter2() > 0 && <span className="info">{ countFilter2() }</span> } <img className='triggerIcon' src={ Images.down } alt="" /></p></div> }
                  triggerWhenOpen={ <div className='trigger-wrapper'> <img className='icon-collapse' src={ activityFilter.icon } alt="" /> <p className='trigger'>{ activityFilter.label.toUpperCase() } { countFilter2() > 0 && <span className="info">{ countFilter2() }</span> } <img className='triggerIcon' src={ Images.up } alt="" /></p></div> }
                >
                  <div className="checkbox-wrapper">
                    {
                      activityFilter.options.map((opt, id) => {
                        return (
                          <CustomCheckbox onClick={ e => changeLocalAttributes("Event Type", e.target.value, true) } checked={ opt.checked } key={ id } label={ opt.value } normal />
                        );
                      })
                    }
                  </div>
                </Collapsible>
              </div>
            </div>
            :
            <div className="filterContainer">
              <div className="filter">
                <img className='icon' src={ Images.search } alt="" />
                <input type="text" value={ tokenId } onChange={ e => setTokenId(e.target.value) } placeholder={ "Filter by Token ID..." } />
              </div>
              <div className="filter">
                <Collapsible
                  trigger={ <div className='trigger-wrapper'> <img className='icon-collapse' src={ Images.rating } alt="" /> <p className='trigger'>RANK { renderRangeInfo(appliedRank[0], appliedRank[1]) } <img className='triggerIcon' src={ Images.down } alt="" /></p></div> }
                  triggerWhenOpen={ <div className='trigger-wrapper'> <img className='icon-collapse' src={ Images.rating } alt="" /> <p className='trigger'>RANK { renderRangeInfo(appliedRank[0], appliedRank[1]) } <img className='triggerIcon' src={ Images.up } alt="" /></p></div> }
                >
                  <div className="range">
                    <div className="inputs">
                      <input className='range-input fontRegular' placeholder='Min' type="number" min={ 0 } max={ 1000000 } onChange={ e => setMinRank(e.target.value) } value={ minRank } />
                      <span>to</span>
                      <input className='range-input fontRegular' placeholder='Max' type="number" min={ 0 } max={ 1000000 } onChange={ e => setMaxRank(e.target.value) } value={ maxRank } />
                    </div>
                    <div onClick={ () => changeAppliedRange("rank") } className={ `apply ${ !checkRank() ? 'disabled' : "" }` }>Apply</div>
                  </div>
                </Collapsible>
              </div>
              <div className="filter">
                <Collapsible
                  trigger={ <div className='trigger-wrapper'> <img className='icon-collapse' src={ Images.status } alt="" /> <p className='trigger'>STATUS { listed && <span className="info">{ 1 }</span> } <img className='triggerIcon' src={ Images.down } alt="" /></p></div> }
                  triggerWhenOpen={ <div className='trigger-wrapper'> <img className='icon-collapse' src={ Images.status } alt="" /> <p className='trigger'>STATUS { listed && <span className="info">{ 1 }</span> } <img className='triggerIcon' src={ Images.up } alt="" /></p></div> }
                >
                  <div className="checkbox-wrapper">
                    <CustomCheckbox onClick={ e => changeLocalListed(!listed) } normal={ true } checked={ listed } label={ "Listed" } />
                  </div>
                </Collapsible>
              </div>
              <div className="filter">

                <Collapsible
                  trigger={ <div className='trigger-wrapper'> <img className='icon-collapse' src={ Images.price } alt="" /> <p className='trigger'>PRICE { renderRangeInfo(appliedPrice[0], appliedPrice[1]) } <img className='triggerIcon' src={ Images.down } alt="" /></p></div> }
                  triggerWhenOpen={ <div className='trigger-wrapper'> <img className='icon-collapse' src={ Images.price } alt="" /> <p className='trigger'>PRICE { renderRangeInfo(appliedPrice[0], appliedPrice[1]) } <img className='triggerIcon' src={ Images.up } alt="" /></p></div> }
                >
                  <div className="range">
                    <div className="inputs">
                      <input className='range-input fontRegular' placeholder='Min' type="number" min={ 0 } max={ 1000000 } onChange={ e => setMinPrice(e.target.value) } value={ minPrice } />
                      <span>to</span>
                      <input className='range-input fontRegular' placeholder='Max' type="number" min={ 0 } max={ 1000000 } onChange={ e => setMaxPrice(e.target.value) } value={ maxPrice } />
                    </div>
                    <div onClick={ () => changeAppliedRange("price") } className={ `apply ${ !checkPrice() ? 'disabled' : "" }` }>Apply</div>
                  </div>
                </Collapsible>
              </div>
              {
                attributes.map((filter, id) => {
                  return (
                    <div key={ id }>
                      <div className="filter">
                        <Collapsible
                          trigger={ <div className='trigger-wrapper'> <img className='icon-collapse' src={ filter.icon } alt="" /> <p className='trigger'>{ filter.label.toUpperCase() } { countFilter(filter.label) > 0 && <span className="info">{ countFilter(filter.label) }</span> } <img className='triggerIcon' src={ Images.down } alt="" /></p></div> }
                          triggerWhenOpen={ <div className='trigger-wrapper'> <img className='icon-collapse' src={ filter.icon } alt="" /> <p className='trigger'>{ filter.label.toUpperCase() } { countFilter(filter.label) > 0 && <span className="info">{ countFilter(filter.label) }</span> } <img className='triggerIcon' src={ Images.up } alt="" /></p></div> }
                        >
                          <div className="checkbox-wrapper">
                            {
                              filter.options.map((opt, id) => {
                                return (
                                  <CustomCheckbox onClick={ e => changeLocalAttributes(filter.label, e.target.value) } checked={ opt.checked } key={ id } label={ opt.value } count={ opt.count } />
                                );
                              })
                            }
                          </div>
                        </Collapsible>
                      </div>
                    </div>
                  );
                })
              }
            </div>
      }
    </StyledFilter>
  );
}

export default Filter;