import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import ReactDropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import ContentLoader from 'react-content-loader';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment/moment';
import { BrowserView, MobileView, isBrowser, isMobile, isTablet } from 'react-device-detect';
import {
  fetchMarket,
} from "../redux/nfts/nftsActions";
import { StyledGallery, StyledDetailModal } from '../styled';
import Images from "../assets/images";
import { fetchNfts, fetchMyOffers, changeFilter, clearNft, fetchActivity } from "../redux/nfts/nftsActions";
import { NftDetail } from "./index";
import abbreviate from '../helpers/abbreviate';
import truncateOwner from '../helpers/truncateOwner';
import Filter from './index';
import { set } from 'lodash';
// import usePrevious from '../helpers/usePrevious';

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

function Gallery(props) {
  let {
    resetNft,
    setGalleryMenu,
    setResetFilter,
    openFilter,
    setOpenFilter,
    tab,
    changedActivity,
    setChangedActivity,
    showFilter,
    reverseAnimation
  } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const previousController = useRef();
  const blockchain = useSelector((state) => state.blockchain);
  const nftsData = useSelector((state) => state.nfts);
  const fetchFilter = useSelector((state) => state.nfts.fetchFilter);
  const activityLoading = useSelector((state) => state.nfts.activityLoading);
  const activityData = useSelector((state) => state.nfts.activityData);
  const activityFilter = useSelector((state) => state.nfts.activityFilter);
  const data = useSelector((state) => state.data);
  const totalNftMinted = useSelector((state) => state.data.totalSupply);

  const [nfts, setNfts] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState("all");
  const [activityLoadingArr, setActivityLoadingArr] = useState([1, 2, 3, 4]);
  // const prevSelectedMenu = usePrevious(selectedMenu);
  const [prevSelectedMenu, setPrevSelectedMenu] = useState(selectedMenu);
  const [selectedNft, setSelectedNft] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpen2, setIsOpen2] = useState(false);
  const [isLoadingCancel, setIsLoadingCancel] = useState(false);
  const [offerList, setofferList] = useState([]);
  const [modal2Content, setModal2Content] = useState("");
  const [count, setCount] = useState(1);
  const [openSort, setOpenSort] = useState(false);
  const [isInfinite, setIsInfinite] = useState(false);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    MARKET_ADDRESS: "",
    SCAN_LINK: "",
    API: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
    MAX_ITEM_PER_MINT: 0,
    MAX_ITEM_PER_MINT_NON_VIP: 0,
    OWNED_LINK: "",
    TX_LINK: "",
    IMAGE_LINK: ''
  });

  const [localActivityData, setLocalActivityData] = useState([]);

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  const vw = document.body.clientWidth;

  let sortOptions = [
    {
      label: "Rarity",
      value: ["rank", 1]
    },
    {
      label: "Token ID",
      value: ["tokenId", 1]
    },
  ];

  if (location.pathname === '/market') {
    sortOptions = [
      {
        label: "Price: Low to High",
        value: ["listedPrice", 1]
      },
      {
        label: "Price: High to Low",
        value: ["listedPrice", -1]
      },
      ...sortOptions,
    ];
  }

  let loaderArray = [1, 2, 3, 4, 5, 6, 7, 8];

  const [selectedSort, setSelectedSort] = useState(sortOptions[0]);

  useEffect(() => {
    sortOptions.forEach(el => {
      if (fetchFilter[el]) {
        setSelectedSort(el);
      }
    });
    fetchOffers();
    if (location.state === "myPP") {
      setSelectedMenu("mine");
    }
    getConfig();
  }, []);

  useEffect(() => {
    dispatch(fetchMarket());
  }, []);

  useEffect(() => {
    if (resetNft) {
      alert('triggered');
      // let galleryEl = document.getElementById('nftContainer');
      // galleryEl.scroll({ top: 0, behavior: 'smooth' });
      // setNfts([]);
    }
  }, [resetNft]);

  useEffect(() => {
    if (modalIsOpen2) {
      dispatch(fetchMyOffers({ address: blockchain.account }));
    }
  }, [modalIsOpen2]);

  const changePreviousController = value => {
    previousController.current = value;
  };

  const changeSort = (option) => {
    let galleryEl = document.getElementById('nftContainer');
    galleryEl && galleryEl.scroll && galleryEl.scroll({ top: 0, behavior: 'smooth' });
    if (previousController.current) {
      previousController.current.abort();
    }
    setSelectedSort(option);
    let filter = { ...fetchFilter };
    filter.sort = {};
    if (option.value[0] === 'listedPrice') {
      filter.sort.listed = -1;
    }
    filter.sort[option.value[0]] = option.value[1];
    dispatch(changeFilter(filter));
    // dispatch(fetchNfts({ page: nftsData.page, limit: nftsData.limit }, filter));
    setNfts([]);
    dispatch(clearNft());
    fetchMore(filter, null, null, null, changePreviousController);
  };

  const fetchMore = (filter, infinite, delayed, resetNextPage, changePreviousController) => {
    setIsInfinite(infinite ? true : false);
    let params = {
      page: resetNextPage ? 1 : infinite ? nftsData.nextPage : 1,
      limit: nftsData.limit,
    };
    if (selectedMenu === "mine" || location.state === "myPP") params.address = blockchain.account;
    // if (selectedMenu === "offer") params.offers = blockchain.account;
    dispatch(fetchNfts(params, filter, delayed || false, changePreviousController));
  };

  const fetchOffers = () => {
    let params = {
      page: 1,
      limit: 100,
      address: blockchain.account
    };
    dispatch(fetchMyOffers(params));
  };

  function openNftDetail(nft) {
    setIsOpen(true);
    setSelectedNft(nft);
  }
  function closeNftDetail() {
    setIsOpen(false);
    setSelectedNft(null);
  }

  const closeOffers = () => {
    setIsOpen2(false);
    // setSelectedNft(null);
    // setModal2Content("");
  };

  const cancelOffer = (nonce, id) => {
    setIsLoadingCancel(id);
    blockchain.marketContract.methods
      .cancel(nonce)
      .send({ from: blockchain.account })
      .once("error", (err) => {
        setIsLoadingCancel(false);
      })
      .then((receipt) => {
        setIsLoadingCancel(false);
        dispatch(fetchMyOffers({ address: blockchain.account }));
        // setIsOpen(false);
        // @nyunk disini harusnya handle dia refetch lagi data detainya biar jadi kereset
      })
      .catch(err => {
        setIsLoadingCancel(false);

      })
      .finally(() => {
        setIsLoadingCancel(false);
      });
  };

  const OfferListModal = () => {
    return (
      <div className="offerListModal">
        <div className="top-offer">
          <p>Top Offer: </p>
          <img src={ Images.pulseIcon } alt="" />
          <p className="amount">{ !nftsData.myOffersLoading && nftsData.myOffers.length > 0 ? nftsData.myOffers[0].bestOffer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0" }</p>
        </div>
        <div className="offers">
          {
            nftsData.myOffersLoading &&
            <div className="loading">
              <img src={ Images.loading } alt="" />
            </div>
          }
          { !nftsData.myOffersLoading && nftsData.myOffers.length > 0 &&
            nftsData.myOffers.map((offer, id) => {
              return (
                <div key={ id } className="offer">
                  {
                    isMobile &&
                    <div className="left">
                      <div className="punk">
                        <img src={ offer.imageURI } alt="" />
                        <p className="name">{ offer.name.split(" ")[1] }</p>
                      </div>
                      <div className="price">
                        <img src={ Images.pulseIcon } alt="" />
                        <p className="amount">{ offer.bestOffer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }</p>
                      </div>
                    </div>
                  }
                  {
                    !isMobile &&
                    <div className="punk">
                      <img src={ offer.imageURI } alt="" />
                      <p className="name">{ offer.name.split(" ")[1] }</p>
                    </div>
                  }
                  {
                    !isMobile &&
                    <div className="price">
                      <img src={ Images.pulseIcon } alt="" />
                      <p className="amount">{ offer.bestOffer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }</p>
                    </div>
                  }
                  {/* <p className="user">
                    <span className="userId">{ truncateOwner(offer.maker) }</span>
                  </p> */}
                  <p className="expiry fontRegular">
                    { "Expiry: " + moment(new Date(offer.endTime)).fromNow() }
                  </p>
                  {
                    // offer.maker?.toLowerCase() ==
                    // blockchain.account?.toLowerCase() && (
                    <div className="cancel">
                      <div
                        className="cancel-btn fontRegular"
                        onClick={ () => cancelOffer(offer.offerNonce, id) }
                      >
                        { isLoadingCancel === id ? "Loading" : "Cancel" }
                      </div>
                    </div>
                    // ) 
                  }
                </div>
              );
            }) }
        </div>
      </div>
    );
  };

  // useEffect(() => {
  //   if (modal2Content) {
  //     setIsOpen2(true);
  //   }
  // }, [modal2Content]);

  useEffect(() => {
    if (selectedMenu !== prevSelectedMenu) {
      setPrevSelectedMenu(selectedMenu);
      setGalleryMenu(selectedMenu);
      let galleryEl = document.getElementById('nftContainer');
      galleryEl && galleryEl.scroll && galleryEl.scroll({ top: 0, behavior: 'smooth' });
      if (previousController.current) {
        previousController.current.abort();
      }
      setNfts([]);
      dispatch(clearNft());
      fetchMore(fetchFilter, true, false, true, changePreviousController);
    }
  }, [selectedMenu]);

  useEffect(() => {
    setNfts([]);
    let filter = { ...fetchFilter };
    filter.sort = {};
    if (sortOptions[0].value[0] === 'listedPrice') {
      filter.sort.listed = -1;
    }
    filter.sort[sortOptions[0].value[0]] = sortOptions[0].value[1];
    dispatch(changeFilter(filter));
    fetchMore(filter);
    return () => {
      dispatch(clearNft());
    };
  }, []);

  useEffect(() => {
    if (selectedMenu === 'all' && nftsData.nfts && nftsData.loading === false) {
      let arr = [];
      nftsData.nfts.forEach(nft => {
        arr.push(nft);
      });
      setNfts(arr);
    }

    if (selectedMenu === 'mine' && nftsData.myPP && nftsData.loading === false) {
      let arr = [];
      nftsData.myPP.forEach(nft => {
        arr.push(nft);
      });
      setNfts(arr);
    }
  }, [nftsData]);

  useEffect(() => {
    if (nftsData.loading && nfts.length == 0) {
      // setNfts([]);
      // let galleryEl = document.getElementById('nftContainer');
      // galleryEl.scroll({ top: 0, behavior: 'smooth' });
    }
  }, [nftsData.loading]);


  useEffect(() => {
    if (activityLoading === false) {
      let temp = [...localActivityData];
      activityData.forEach(el => {
        // collectionAddress: "0x6c665e2d13a8c5283e7101c6d42857d2c92c33ef";
        // createdAt: "2023-01-29T03:41:27.972Z";
        // event: "Listing";
        // from: "0x826ee8601d3f063c819d1a99a623d76986771e53";
        // to: "-";
        // tokenId: "5208";
        // txHash: "0x244c26410bdc6fa5278251472956ce8aea69677c69697d9d3ed71510902ed465";
        // updatedAt: "2023-01-29T03:41:27.972Z";
        // _id: "63d5eae73614fcea08a91f0d";
        temp.push(
          {
            image: `${ CONFIG.IMAGE_LINK }punk${ el.tokenId }@20x.png`,
            name: `PULSEPUNK #${ el.tokenId }`,
            type: el.event,
            price: el.priceInEther || null,
            from: el.from,
            to: el.to,
            tx: el.txHash,
            address: el.collectionAddress,
            time: moment(el.createdAt)
          }
        );
      });
      setLocalActivityData(temp);
    }
  }, [activityLoading]);

  const fetchMoreActivity = () => {
    setChangedActivity(false);
    let arr = [];
    activityFilter.options.forEach(el => {
      if (el.checked && arr.indexOf(el.code) == -1) {
        arr.push(el.code);
      }
    });
    let params = {
      page: nftsData.activityNextPage,
      limit: nftsData.activityLimit,
    };
    let body = {
      "tokenId": activityFilter.tokenId,
      "event": arr
    };
    setActivityLoadingArr([1]);
    dispatch(fetchActivity(params, body));
  };

  useEffect(() => {
    if (tab === 'activity' || changedActivity) {
      setLocalActivityData([]);
      setChangedActivity(false);
      let arr = [];
      activityFilter.options.forEach(el => {
        if (el.checked && arr.indexOf(el.code) == -1) {
          arr.push(el.code);
        }
      });
      let body = {
        "tokenId": activityFilter.tokenId,
        "event": arr
      };
      setActivityLoadingArr([1, 2, 3, 4]);
      dispatch(fetchActivity({}, body));
    }
  }, [tab, changedActivity]);

  const compareDataLengthWithSelectedMenu = () => {
    if (selectedMenu === 'all') {
      return nftsData.nfts.length > 0;
    } else if (selectedMenu === 'mine') {
      return nftsData.myPP.length > 0;
    }
  };

  useEffect(() => {
    if (nftsData.hasNextPage && nfts.length === 8) {
      fetchMore(fetchFilter, true);
    }
  }, [nftsData.hasNextPage, nfts.length]);


  return (
    <StyledGallery id="gallery" loggedIn={ blockchain.account } className="wow fadeInUpBig" tab={ tab } showFilter={ showFilter }>
      {
        !isMobile &&
        <div id="showFilter" onClick={ () => reverseAnimation(true) || null } className="hide">
          <img src={ Images.filter2 } alt="" />
          <span>Show Filter</span>
        </div>
      }
      {
        tab === 'activity'
          ?
          <div className="header">
            <div className="tableHeader">
              {
                isMobile &&
                <img className='filter' onClick={ () => setOpenFilter(!openFilter) } src={ Images.filter } style={ { width: "25px" } } alt="" />
              }
              <div className="desc"></div>
              <div className="item">Type</div>
              <div className="item">Price</div>
              <div className="item">From</div>
              <div className="item">To</div>
              <div className="item">Time</div>
            </div>
          </div>
          :
          <div className="header">
            <div className='info'>
              <div className="div">
                <div className="switch">
                  <div onClick={ () => { navigate(location.pathname, { replace: true, state: null }); setSelectedMenu('all'); } } className={ selectedMenu === "all" ? "switch-menu-active" : "switch-menu" }>
                    ALL PP'S
                  </div>
                  {
                    blockchain.account !== null && blockchain.errorMsg == "" &&
                    <div onClick={ () => { navigate(location.pathname, { replace: true, state: null }); setSelectedMenu('mine'); } } className={ selectedMenu === "mine" ? "switch-menu-active" : "switch-menu" }>
                      MY PP'S
                    </div>
                  }
                  {/* {
                  blockchain.account !== null && blockchain.errorMsg == "" &&
                  <div onClick={ () => setSelectedMenu('offer') } className={ selectedMenu === "offer" ? "switch-menu-active" : "switch-menu" }>
                    My Offer
                  </div>
                } */}
                </div>

                <div className="div">

                  {
                    blockchain.account !== null && blockchain.errorMsg == "" && location.pathname === "/market" &&
                    <div className="myOffer" onClick={ () => setIsOpen2(true) }>
                      <img src={ Images.cart } alt="" />
                    </div>
                  }


                </div>
                <div className="count fontRegular">
                  {
                    nftsData.loading && !isInfinite
                      ?
                      <ContentLoader
                        speed={ 2 }
                        width={ 20 }
                        height={ 18 }
                        viewBox="0 0 20 18"
                        backgroundColor="#7d7d7d"
                        foregroundColor="#ffffff"
                        style={ { marginRight: 4 } }
                      >
                        <rect x="1" y="-3" rx="0" ry="0" width="20" height="18" />
                      </ContentLoader>
                      :
                      <span>{ nftsData.totalDocs + " " }</span>
                  }
                  <span className='total'>/ { totalNftMinted }</span>
                </div>
              </div>
              {
                isMobile
                  ?
                  <div className="div mobile">
                    <img onClick={ () => setOpenSort(!openSort) } src={ Images.sort } alt="" />
                    <img onClick={ () => setOpenFilter(!openFilter) } src={ Images.filter } alt="" />
                  </div>
                  :
                  <div className="div">

                    <p>Sort by</p>
                    <ReactDropdown
                      options={ sortOptions }
                      onChange={ drop => changeSort(drop) }
                      arrowOpen={ <img src={ Images.arrowDown } alt="" /> }
                      arrowClosed={ <img src={ Images.arrowDown } alt="" /> }
                      value={ selectedSort }
                    />
                  </div>
              }
            </div>
            {
              openSort &&
              <div className="sort">
                <div className="wrapper">
                  <span>Sort by</span>
                  <ReactDropdown
                    options={ sortOptions }
                    onChange={ drop => changeSort(drop) }
                    arrowOpen={ <img src={ Images.arrowDown } alt="" /> }
                    arrowClosed={ <img src={ Images.arrowDown } alt="" /> }
                    value={ selectedSort }
                  />
                </div>
              </div>
            }
          </div>
      }
      {
        tab === 'activity' & location.pathname === "/market"
          ?
          <div id="activityContainer">
            <InfiniteScroll
              className='activityContainer'
              scrollableTarget='activityContainer'
              dataLength={ localActivityData.length } //This is important field to render the next data
              next={ fetchMoreActivity }
              hasMore={ nftsData.activityHasNextPage }
            >
              {
                localActivityData.map((el, i) => {
                  return (
                    <div key={ i } className="activityCard">
                      <span className="desc">
                        <img src={ el.image } alt="" onError={ (event) => event.target.src = Images.nftPlaceholder } />
                        <span className="name">{ el.name }</span>
                      </span>
                      <span className="item">{ el.type }</span>
                      <span className="item">{ el.price ? <img src={ Images.pulseIcon } alt="" /> : null }{ el.price ? abbreviate(el.price) : '-' }</span>
                      <a className="item blue" href={ `${ CONFIG.OWNED_LINK }${ el.from }` } target="_blank">
                        {
                          el.from !== "-"
                            ?
                            el.from.substring(0, 4) +
                            "...." +
                            el.from.substring(el.from.length - 4)
                            :
                            "-"
                        }
                      </a>
                      <a className={ `item blue ${ el.to == '-' ? 'disabled' : '' }` } href={ `${ CONFIG.OWNED_LINK }${ el.to }` } target="_blank">
                        {
                          el.to !== "-"
                            ?
                            el.to.substring(0, 4) +
                            "...." +
                            el.to.substring(el.to.length - 4)
                            :
                            "-"
                        }
                      </a>
                      <a href={ `${ CONFIG.TX_LINK }${ el.tx }` } target="_blank" className="item blue">{ moment(el.time).fromNow() } <img src={ Images.externalLink } alt="" className="external" /></a>
                    </div>
                  );
                })
              }
              {
                activityLoading &&
                activityLoadingArr.map((el, id) => {
                  return (
                    <div key={ id } className="activityCard">
                      <span className="desc">
                        {/* <img src={ el.image } alt="" />
                      <span className="name">{ el.name }</span> */}
                        <ContentLoader
                          speed={ 2 }
                          width={ 227 }
                          height={ 60 }
                          viewBox="0 0 227 80"
                          backgroundColor="#7d7d7d"
                          foregroundColor="#ffffff"
                          className='loader'
                        >
                          <rect x="0" y="0" rx="0" ry="0" width="80" height="80" />
                          <rect x="120" y="20" rx="0" ry="0" width="100" height="30" />
                        </ContentLoader>
                      </span>
                      <span className="item">
                        <ContentLoader
                          speed={ 2 }
                          width={ 120 }
                          height={ 60 }
                          viewBox="0 0 120 80"
                          backgroundColor="#7d7d7d"
                          foregroundColor="#ffffff"
                          className='loader'
                        >
                          <rect x="0" y="20" rx="0" ry="0" width="100" height="30" />
                        </ContentLoader>
                      </span>
                      <span className="item">
                        <ContentLoader
                          speed={ 2 }
                          width={ 120 }
                          height={ 60 }
                          viewBox="0 0 120 80"
                          backgroundColor="#7d7d7d"
                          foregroundColor="#ffffff"
                          className='loader'
                        >
                          <rect x="0" y="20" rx="0" ry="0" width="100" height="30" />
                        </ContentLoader>
                      </span>
                      <a className="item blue">
                        <ContentLoader
                          speed={ 2 }
                          width={ 120 }
                          height={ 60 }
                          viewBox="0 0 120 80"
                          backgroundColor="#7d7d7d"
                          foregroundColor="#ffffff"
                          className='loader'
                        >
                          <rect x="0" y="20" rx="0" ry="0" width="100" height="30" />
                        </ContentLoader>
                      </a>
                      <a className="item blue">
                        <ContentLoader
                          speed={ 2 }
                          width={ 120 }
                          height={ 60 }
                          viewBox="0 0 120 80"
                          backgroundColor="#7d7d7d"
                          foregroundColor="#ffffff"
                          className='loader'
                        >
                          <rect x="0" y="20" rx="0" ry="0" width="100" height="30" />
                        </ContentLoader>
                      </a>
                      <a className="item blue">
                        <ContentLoader
                          speed={ 2 }
                          width={ 120 }
                          height={ 60 }
                          viewBox="0 0 120 80"
                          backgroundColor="#7d7d7d"
                          foregroundColor="#ffffff"
                          className='loader'
                        >
                          <rect x="0" y="20" rx="0" ry="0" width="100" height="30" />
                        </ContentLoader>
                      </a>
                    </div>
                  );
                })
              }
            </InfiniteScroll>
          </div>
          :
          <div id='nftContainer'>
            <InfiniteScroll
              className='nftContainer'
              scrollableTarget='nftContainer'
              dataLength={ nfts.length } //This is important field to render the next data
              next={ () => fetchMore(fetchFilter, true) }
              hasMore={ nftsData.hasNextPage }
            // hasMore={ selectedMenu === "mine" ? false : nftsData.hasNextPage }
            // loader={ <h4>Loading...</h4> }
            // endMessage={
            //   <p style={ { textAlign: 'center' } }>
            //     <b>Yay! You have seen it all</b>
            //   </p>
            // }
            >
              {
                compareDataLengthWithSelectedMenu() &&
                nfts.map((nft, id) => {
                  return (
                    <div onClick={ () => openNftDetail(nft) } key={ id } className="nftCard">
                      <p className='rank'><span className='fontRegular'>Rank </span><label>#{ nft.rank }</label></p>
                      <div className="img-wrapper">
                        <img className='nft-img' src={ nft.imageURI } alt="" onError={ (event) => event.target.src = Images.nftPlaceholder } />
                      </div>
                      <div className="detail">
                        <p className="name">{ nft.name }</p>
                        <div className="amount">
                          {
                            nft.listedPrice
                              ?
                              <div className="price">
                                <p className="label fontRegular">Price <img src={ Images.pulseIcon } alt="" /></p>
                                <p className="number">{ abbreviate(nft.listedPrice, 2) }</p>
                              </div>
                              :
                              <div className="price" />
                          }
                          {
                            nft.bestOffer
                              ?
                              <div className="offer">
                                <p className="label">Offer<img src={ Images.pulseIcon } alt="" /></p>
                                <p
                                  className="number"
                                  style={ { color: nft.bestOffer >= 5000 ? "#ced3d4" : "#7C0A02" } }
                                >{ abbreviate(nft.bestOffer, 2) }</p>
                              </div>
                              :
                              <div className="offer" />
                          }
                        </div>
                      </div>
                    </div>
                  );
                })
              }
              {
                !nftsData.loading &&
                nfts.length === 0 &&
                <div className="emptyState">
                  <span>No Pulse Punks matched your filter parameters.</span>
                  <div onClick={ () => setResetFilter(true) } className="reset">Reset Filter</div>
                </div>
              }
              {
                nftsData.loading &&
                loaderArray.map((el, id) => {
                  return (
                    <div key={ id } className="nftCard">
                      <p className='rank'>
                        <ContentLoader
                          speed={ 2 }
                          width={ 90 }
                          height={ isMobile ? 20 : 34 }
                          viewBox={ `0 0 90 ${ isMobile ? 20 : 34 }` }
                          backgroundColor="#7d7d7d"
                          foregroundColor="#ffffff"
                        >
                          <rect x="1" y="3" rx="0" ry="0" width="90" height={ `${ isMobile ? 20 : 34 }` } />
                        </ContentLoader>
                      </p>
                      <ContentLoader
                        style={ { marginBottom: "10px" } }
                        speed={ 2 }
                        width={ isMobile ? ((40 / 100) * vw) : 167 }
                        height={ isMobile ? ((40 / 100) * vw) : 167 }
                        viewBox={ `0 0 ${ isMobile ? ((40 / 100) * vw) + " " + ((40 / 100) * vw) : "167 167" }` }
                        backgroundColor="#7d7d7d"
                        foregroundColor="#ffffff"
                      >
                        <rect x="0" y="0" rx="0" ry="0" width={ `${ isMobile ? ((40 / 100) * vw) : 167 }` } height={ `${ isMobile ? ((40 / 100) * vw) : 167 }` } />
                      </ContentLoader>
                      {/* <div className="img-wrapper"> */ }
                      {/* <img className='nft-img' src={ nft.imageURI } alt="" /> */ }
                      {/* </div> */ }
                      <div className="detail">
                        <p className="name">
                          <ContentLoader
                            speed={ 2 }
                            width={ 120 }
                            height={ isMobile ? 20 : 34 }
                            viewBox={ `0 0 120 ${ isMobile ? 20 : 34 }` }
                            backgroundColor="#7d7d7d"
                            foregroundColor="#ffffff"
                          >
                            <rect x="1" y="3" rx="0" ry="0" width="120" height={ isMobile ? 20 : 34 } />
                          </ContentLoader>
                        </p>

                      </div>
                    </div>
                  );
                })
              }
            </InfiniteScroll>
          </div>
      }
      <Modal
        isOpen={ modalIsOpen }
        onAfterOpen={ () => { } }
        onRequestClose={ closeNftDetail }
        style={ customStyles }
        shouldCloseOnOverlayClick={ true }
        contentLabel=""
      >
        {
          selectedNft &&
          <NftDetail
            detail={ selectedNft }
            setModal2Content={ setModal2Content }
            setSelectedNft={ setSelectedNft }
            setIsOpen={ setIsOpen }
            fetchMore={ fetchMore }
            setNfts={ setNfts }
          />
        }
      </Modal>

      <Modal
        isOpen={ modalIsOpen2 }
        onAfterOpen={ () => { } }
        // onRequestClose={ () => closeModal() }
        style={ customStyles }
      >
        <StyledDetailModal>
          <span className="label">My Offers</span>
          <img
            src={ Images.close }
            onClick={ () => (isLoadingCancel ? {} : closeOffers()) }
            alt=""
            className="close"
          />
          { OfferListModal() }
        </StyledDetailModal>
      </Modal>
    </StyledGallery>
  );
}

export default Gallery;