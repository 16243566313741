import React from 'react';

import { StyledTooltip } from '../styled';
import Images from '../assets/images';

function InfoTooltip(props) {
  let { text } = props;
  return (
    <StyledTooltip>
      <img src={ Images.info } alt="" />
      <div class="top fontRegular">
        <p>{ text }</p>
        <i></i>
      </div>
    </StyledTooltip>
  );
}

export default InfoTooltip;