import store from "../store";
import hitAPI from "../../helpers/hitAPI";

const punkAddress = "0xf886F928e317cfD4085137A7a755C23D87F81908".toLowerCase();

const fetchNftsRequest = () => {
  return {
    type: "FETCH_NTFS_REQUEST",
  };
};

const fetchNftsSuccess = (payload, myPP) => {
  return {
    type: "FETCH_NTFS_SUCCESS",
    payload,
    myPP: myPP || false,
  };
};

const fetchFilterSuccess = (payload) => {
  return {
    type: "FETCH_FILTER_SUCCESS",
    payload,
  };
};

const fetchMarketSuccess = (payload) => {
  return {
    type: "FETCH_MARKET_SUCCESS",
    payload,
  };
};

const fetchMyOffersLoading = (payload) => {
  return {
    type: "FETCH_MY_OFFERS",
    payload,
  };
};

const fetchMyOffersSuccess = (payload) => {
  return {
    type: "FETCH_MY_OFFERS_SUCCESS",
    payload,
  };
};

const fetchOffersSuccess = (payload) => {
  return {
    type: "FETCH_OFFERS_SUCCESS",
    payload,
  };
};

const clearOffersSuccess = (payload) => {
  return {
    type: "CLEAR_OFFERS_SUCCESS",
    payload,
  };
};

const fetchNftsFailed = (payload) => {
  return {
    type: "FETCH_NTFS_FAILED",
    payload,
  };
};

const changeFilterSuccess = (payload) => {
  return {
    type: "CHANGE_FILTER",
    payload,
  };
};

const changeMultipleFiltersSuccess = (payload) => {
  return {
    type: "CHANGE_MULTIPLE_FILTERS",
    payload,
  };
};

const clearNftSuccess = (payload) => {
  return {
    type: "CLEAR_NFT",
    payload,
  };
};

const changeActivityFilterSuccess = (payload) => {
  return {
    type: "FILTER_ACTIVITY_SUCCESS",
    payload,
  };
};

const fetchActivityRequest = () => {
  return {
    type: "FETCH_ACTIVITY_REQUEST",
  };
};

const fetchActivitySuccess = (payload) => {
  return {
    type: "FETCH_ACTIVITY_SUCCESS",
    payload,
  };
};

export const fetchFilter = () => {
  return async (dispatch) => {
    // dispatch(fetchNftsRequest());
    try {
      const url = `https://punk.dapsdev.com/collection-info/${punkAddress}`;
      const response = await hitAPI(url, "GET", {}, false);
      const res = await response.json();
      dispatch(fetchFilterSuccess(res.result));
    } catch (err) {
      console.log("err", err);
      dispatch(fetchNftsFailed("Could not load filter data"));
      throw err;
    }
  };
};

export const fetchMarket = () => {
  return async (dispatch) => {
    // dispatch(fetchNftsRequest());
    try {
      const url = `https://punk.dapsdev.com/collection-info/${punkAddress}`;
      const response = await hitAPI(url, "GET", {}, false);
      const res = await response.json();
      dispatch(fetchMarketSuccess(res.result));
    } catch (err) {
      console.log("err", err);
      dispatch(fetchNftsFailed("Could not load filter data"));
      throw err;
    }
  };
};

export const fetchNfts = (params, body, delayed, changePreviousController) => {
  return async (dispatch) => {
    dispatch(fetchNftsRequest());
    setTimeout(
      async () => {
        try {
          let page = params.page || 1;
          let limit = params.limit || 20;
          let method = "POST";
          let url = `https://punk.dapsdev.com/metadata/punks?page=${page}&limit=${limit}`;
          if (params.address) {
            url = `https://punk.dapsdev.com/metadata/mypunks/${params.address}?page=${page}&limit=${limit}`;
          }
          // if (params.offers) {
          //   url = `https://punk.dapsdev.com/metadata/myoffers/${params.offers}`;
          //   method = "GET";
          // }
          const response = await hitAPI(
            url,
            method,
            body,
            false,
            changePreviousController || null
          );
          const res = await response.json();
          // if (params.offers) {
          //   let result = {
          //     docs: [],
          //     hasNextPage: false,
          //     hasPrevPage: false,
          //     limit: 10,
          //     nextPage: null,
          //     page: 1,
          //     pagingCounter: 1,
          //     prevPage: null,
          //     totalDocs: 0,
          //     totalPages: 1,
          //   };
          //   res.result.forEach((el) => {
          //     result.docs.push({
          //       ...el.punks,
          //       bestOffer: el.bestOffer ? el.bestOffer : null,
          //     });
          //     result.totalDocs++;
          //   });
          //   dispatch(fetchNftsSuccess(result));
          // } else {
          // }
          dispatch(fetchNftsSuccess(res.result, params.address ? true : false));
        } catch (err) {
          console.log("err", err);
          if (err.name == "AbortError") {
          } else {
            dispatch(fetchNftsFailed("Could not load nft data"));
          }
          throw err;
        }
      },
      delayed ? 1000 : 1
    );
  };
};

export const fetchMyOffers = (params) => {
  return async (dispatch) => {
    dispatch(fetchMyOffersLoading());
    try {
      const url = `https://punk.dapsdev.com/metadata/myoffers/${params.address}`;
      const response = await hitAPI(url, "GET", {}, false);
      const res = await response.json();
      let result = [];
      res.result.forEach((el) => {
        result.push({
          ...el.punks,
          bestOffer: el.bestOffer,
          offerNonce: el.offerNonce,
          endTime: el.endTime,
        });
      });
      result.sort((a, b) => {
        return parseFloat(b.bestOffer) - parseFloat(a.bestOffer);
      });
      dispatch(fetchMyOffersSuccess(result));
    } catch (err) {
      console.log("err", err);
      // dispatch(fetchNftsFailed("Could not load nft data"));
      throw err;
    }
  };
};

export const fetchOffers = (params) => {
  return async (dispatch) => {
    // dispatch(fetchNftsRequest());
    try {
      const url = `https://punk.dapsdev.com/offer/${params.tokenId}`;
      const response = await hitAPI(url, "POST", {}, false);
      const res = await response.json();
      dispatch(fetchOffersSuccess(res.result));
    } catch (err) {
      console.log("err", err);
      // dispatch(fetchNftsFailed("Could not load nft data"));
      throw err;
    }
  };
};

export const clearOffers = () => {
  return async (dispatch) => {
    dispatch(clearOffersSuccess());
  };
};

export const changeFilter = (payload) => {
  return async (dispatch) => {
    dispatch(changeFilterSuccess(payload));
  };
};

export const changeMultipleFilters = (payload) => {
  return async (dispatch) => {
    dispatch(changeMultipleFiltersSuccess(payload));
  };
};

export const clearNft = () => {
  return async (dispatch) => {
    dispatch(clearNftSuccess());
  };
};

export const changeActivityFilter = (params, body) => {
  return async (dispatch) => {
    dispatch(changeActivityFilterSuccess(body));
  };
};

export const fetchActivity = (params, body) => {
  return async (dispatch) => {
    dispatch(fetchActivityRequest());
    try {
      let page = params.page || 1;
      let limit = params.limit || 10;
      const url = `https://punk.dapsdev.com/event/type?page=${page}&limit=${limit}`;
      const response = await hitAPI(url, "POST", body || {}, false);
      const res = await response.json();
      dispatch(fetchActivitySuccess(res.result));
    } catch (err) {
      console.log("err", err);
      // dispatch(fetchNftsFailed("Could not load nft data"));
      throw err;
    }
  };
};
