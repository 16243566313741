import React, { useState, useEffect } from 'react';

import { StyledCheckBox } from '../styled';

function customCheckbox(props) {
  let { label, count, onClick, checked, normal } = props;
  return (
    <StyledCheckBox>
      <label className="container fontRegular">
        <p>{ label } { !normal && <span className='count'>({ count })</span> }</p>
        <input value={ label } checked={ checked } onChange={ onClick } type="checkbox" />
        <span className="checkmark"></span>
      </label>
    </StyledCheckBox>
  );
}

export default customCheckbox;