// constants
import Web3EthContract, { Contract } from "web3-eth-contract";
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
const providerOptions = {
  coinbasewallet: {
    package: CoinbaseWalletSDK, // Required
    options: {
      appName: "PULSE MINTER", // Required
      infuraId: "e3edd2d2370a4dcca86e94ee02c9d702", // Required
      chainId: 369, // Optional. It defaults to 1 if not provided
    },
  },
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      rpc: {
        369: "https://rpc.pulsechain.com",
      },
      infuraId: "e3edd2d2370a4dcca86e94ee02c9d702", // required
    },
  },
};

const web3Modal = new Web3Modal({
  network: "mainnet", // optional
  cacheProvider: false, // optional
  providerOptions, // required
});
// log
import { fetchData } from "../data/dataActions";

const connectRequest = () => {
  return {
    type: "CONNECTION_REQUEST",
  };
};

const connectSuccess = (payload) => {
  return {
    type: "CONNECTION_SUCCESS",
    payload: payload,
  };
};

const connectFailed = (payload) => {
  return {
    type: "CONNECTION_FAILED",
    payload: payload,
  };
};

const updateAccountRequest = (payload) => {
  return {
    type: "UPDATE_ACCOUNT",
    payload: payload,
  };
};

export const connect = () => {
  return async (dispatch) => {
    dispatch(connectRequest());
    const abiResponse = await fetch("/config/abi.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const marketAbiResponse = await fetch("/config/market.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const royaltyAbiResponse = await fetch("/config/royaltyDisperse.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const abi = await abiResponse.json();
    const marketAbi = await marketAbiResponse.json();
    const royaltyAbi = await royaltyAbiResponse.json();
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const CONFIG = await configResponse.json();
    // const { ethereum } = window;
    // const metamaskIsInstalled = ethereum && ethereum.isMetaMask;
    // if (metamaskIsInstalled) {
    //   Web3EthContract.setProvider(ethereum);
    //   let web3 = new Web3(ethereum);
    //   try {
    //     const accounts = await ethereum.request({
    //       method: "eth_requestAccounts",
    //     });

    //     const networkId = await ethereum.request({
    //       method: "net_version",
    //     });

    //     if (networkId == CONFIG.NETWORK.ID) {
    //       const SmartContractObj = new Web3EthContract(
    //         abi,
    //         CONFIG.CONTRACT_ADDRESS
    //       );
    //       dispatch(
    //         connectSuccess({
    //           account: accounts[0],
    //           smartContract: SmartContractObj,
    //           web3: web3,
    //         })
    //       );
    //     } else {
    //       dispatch(connectFailed(`Change network to ${CONFIG.NETWORK.NAME}.`));
    //     }

    //     // Add listeners start
    //     ethereum.on("accountsChanged", (accounts) => {
    //       dispatch(updateAccount(accounts[0]));
    //     });
    //     ethereum.on("chainChanged", () => {
    //       dispatch(connect());
    //     });
    //     // Add listeners end
    //   } catch (err) {
    //     console.log(err);
    //     dispatch(connectFailed("Something went wrong."));
    //   }
    // } else {
    //   dispatch(connectFailed("Install Metamask."));
    // }
    const provider = await web3Modal.connect();

    Web3EthContract.setProvider(provider);

    const web3 = new Web3(provider);

    const accounts = await web3.eth.getAccounts();
    const chainId = await web3.eth.getChainId();

    if (chainId == CONFIG.NETWORK.ID) {
      const SmartContractObj = new Web3EthContract(
        abi,
        CONFIG.CONTRACT_ADDRESS
      );
      const MarketContractObj = new Web3EthContract(
        marketAbi,
        CONFIG.MARKET_ADDRESS
      );
      const RoyaltyContractObj = new Web3EthContract(
        royaltyAbi,
        CONFIG.ROYALTY_ADDRESS
      );
      dispatch(
        connectSuccess({
          account: accounts[0],
          smartContract: SmartContractObj,
          marketContract: MarketContractObj,
          royaltyContract: RoyaltyContractObj,
          web3: web3,
          provider,
        })
      );
    } else {
      dispatch(connectFailed(`Change network to ${CONFIG.NETWORK.NAME}.`));
    }

    provider.on("accountsChanged", (accounts) => {
      const SmartContractObj = new Web3EthContract(
        abi,
        CONFIG.CONTRACT_ADDRESS
      );
      const MarketContractObj = new Web3EthContract(
        marketAbi,
        CONFIG.MARKET_ADDRESS
      );
      const RoyaltyContractObj = new Web3EthContract(
        royaltyAbi,
        CONFIG.ROYALTY_ADDRESS
      );
      dispatch(
        connectSuccess({
          account: accounts[0],
          smartContract: SmartContractObj,
          marketContract: MarketContractObj,
          royaltyContract: RoyaltyContractObj,
          web3: web3,
        })
      );
    });

    provider.on("chainChanged", (chainId) => {
      dispatch(connect());
    });
  };
};

export const updateAccount = (account) => {
  return async (dispatch) => {
    dispatch(updateAccountRequest({ account: account }));
    dispatch(fetchData(account));
  };
};
