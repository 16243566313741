import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet,
} from "react-device-detect";
import { useNavigate } from "react-router-dom";

import { StyledMarketInfo } from "../styled";
import Images from "../assets/images";
import abbreviate from "../helpers/abbreviate";
import {
  fetchMarket,
  changeActivityFilter,
  changeFilter,
  changeMultipleFilters,
} from "../redux/nfts/nftsActions";

function marketInfo({ setTab, setChangedActivity }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const activityFilter = useSelector((state) => state.nfts.activityFilter);
  const filtersData = useSelector((state) => state.nfts.filters);
  const fetchFilter = useSelector((state) => state.nfts.fetchFilter);
  const marketData = useSelector((state) => state.nfts.marketData);
  const totalNftMinted = useSelector((state) => state.data.totalSupply);
  let market1 = [
    [
      {
        label: "ITEMS",
        value: abbreviate(totalNftMinted),
        link: "/rarity",
      },
      {
        label: "OWNERS",
        value: marketData.owners ? abbreviate(marketData.owners) : 0,
      },
      {
        label: "FLOOR PRICE",
        icon: true,
        value: marketData.floorPrice ? abbreviate(marketData.floorPrice, 2) : 0,
        param: "floorPrice",
      },
      {
        label: "BEST OFFER",
        icon: marketData.bestOffer > 0,
        value: marketData.bestOffer ? abbreviate(marketData.bestOffer, 2) : 0,
        param: "bestOffer",
      },
      {
        label: "LISTED",
        value: marketData.listed ? abbreviate(marketData.listed) + "%" : "0%",
        param: "listed",
      },
    ],
    [
      {
        label: "SALES COUNT",
        value: marketData.salesCount ? abbreviate(marketData.salesCount) : 0,
        param: "salesCount",
      },
      {
        label: "SALES VOLUME",
        icon: true,
        value: marketData.salesVolume ? abbreviate(marketData.salesVolume, 2) : 0,
        param: "salesVolume",
      },
      {
        label: "HIGHEST SALE PRICE",
        icon: true,
        value: marketData.highestSalePrice
          ? abbreviate(marketData.highestSalePrice, 2)
          : 0,
      },
    ],
  ];
  let market2 = [
    [
      {
        label: "ITEMS",
        value: abbreviate(totalNftMinted),
        link: "/rarity",
      },
      {
        label: "OWNERS",
        value: marketData.owners ? abbreviate(marketData.owners) : 0,
      },
      {
        label: "FLOOR PRICE",
        icon: true,
        value: marketData.floorPrice ? abbreviate(marketData.floorPrice, 2) : "-",
        param: "floorPrice",
      },
      {
        label: "BEST OFFER",
        icon: marketData.bestOffer > 0,
        value: marketData.bestOffer ? abbreviate(marketData.bestOffer, 2) : "-",
        param: "bestOffer",
      },
    ],
    [
      {
        label: "LISTED",
        value: marketData.listed ? abbreviate(marketData.listed) + "%" : "0%",
        param: "listed",
      },
      {
        label: "SALES COUNT",
        value: marketData.salesCount ? abbreviate(marketData.salesCount) : 0,
        param: "salesCount",
      },
      {
        label: "SALES VOLUME",
        icon: true,
        value: marketData.salesVolume ? abbreviate(marketData.salesVolume, 2) : 0,
        param: "salesVolume",
      },
      {
        label: "HIGHEST SALE PRICE",
        icon: true,
        value: marketData.highestSalePrice
          ? abbreviate(marketData.highestSalePrice, 2)
          : 0,
      },
    ],
  ];

  useEffect(() => {
    dispatch(fetchMarket());
  }, []);

  const handleClick = (el) => {
    if (el.param) {
      let value = "";
      if (el.param === "floorPrice" || el.param === "listed") {
        setTab("items");
        dispatch(changeMultipleFilters([{ label: "listed", value: 1 }]));
      }
      if (el.param === "bestOffer") {
        setTab("activity");
        value = "Offers";
      }
      if (el.param === "salesCount" || el.param === "salesVolume") {
        setTab("activity");
        value = "Sales";
      }
      let temp = activityFilter;
      temp.options.forEach((opt) => {
        if (opt.value === value) {
          opt.checked = true;
        } else {
          opt.checked = false;
        }
      });
      dispatch(changeActivityFilter({}, temp));
      setChangedActivity(true);
    }

    if (el.link) {
      setTab("items");
      let filter = { ...fetchFilter };
      filter.sort = {};
      filter.sort.rank = 1;
      dispatch(changeFilter(filter));
      setTimeout(() => {
        navigate(el.link);
      }, 100);
    }
  };

  const renderItem = (id, el) => (
    <div
      key={id}
      className="item"
      onClick={() => (el.link || el.param ? handleClick(el) : null)}
      style={{ cursor: el.link || el.param ? "pointer" : "default" }}
    >
      <p className="value">
        {el.value}
      </p>
      <p className="label fontRegular">{el.label} {el.icon && <img src={Images.pulseIcon} alt="" />} </p>
    </div>
  );

  return (
    <StyledMarketInfo className="wow fadeInUpBig">
      {!isMobile && (
        <div className="left">
          {market1[0].map((el, id) => renderItem(id, el))}
        </div>
      )}
      {!isMobile && (
        <div className="right">
          {market1[1].map((el, id) => renderItem(id, el))}
        </div>
      )}
      {isMobile && (
        <div className="center">
          <div className="div">
            {market2[0].map((el, id) => renderItem(id, el))}
          </div>
          <div className="div">
            {market2[1].map((el, id) => renderItem(id, el))}
          </div>
        </div>
      )}
    </StyledMarketInfo>
  );
}

export default marketInfo;
