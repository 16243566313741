/* eslint-disable no-undef */
import React, { useState } from "react";

const hitAPI = async (
  url,
  method,
  data,
  isUpload,
  changePreviousController
) => {
  const headers = {};
  const controller = new AbortController();
  const signal = controller.signal;
  changePreviousController && changePreviousController(controller);

  if (isUpload) {
    headers["Accept"] = "multipart/form-data";
    // headers['Content-Type'] = 'application/x-www-form-urlencoded';
  } else {
    headers["Accept"] = "application/json; charset=utf-8";
    headers["Content-Type"] = "application/json; charset=utf-8";
  }

  try {
    const response = await fetch(url, {
      method: method,
      body: isUpload
        ? data
        : method !== "GET"
        ? JSON.stringify(data)
        : undefined,
      mode: "cors",
      headers,
      signal: changePreviousController ? signal : null,
    });

    switch (response.status) {
      case 500:
        console.error(response);
        break;

      case 404:
        console.error(response);
        break;

      case 403:
        console.error(response);
        break;

      case 401:
        console.error(response);
        break;

      case 400:
        console.error(response);
        return await response;

      default:
        return await response;
    }
  } catch (err) {
    throw err;
  }
};
export default hitAPI;
